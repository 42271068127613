import React, { Component } from 'react';
import { Link }
    from 'react-router-dom';

import { Table, Container, Card, Button, Col, Row } from 'react-bootstrap';

var EnglishToBanglsNumber = require('../../common/EnglishToBanglsNumber');

var EnglishToBanglaDate = require('../../common/EnglishToBanglaDate');

var EnglishToBanglamonth = require('../../common/EnglishToBanglamonth');

var EnglishToBanglaNumber = require('../../common/EnglishToBanglaNumber');


class PersonalLezarLayout extends Component {


    constructor(props) {

        super(props);
        this.state = {
            data: [],
            user: []

        }




    }

    componentDidMount() {
        console.log('component did Mount');

        this.setState({
            data: this.props.data,
            user: this.state.user
        });

        if (this.props.data) {

            //  console.log(this.props.data);
            this.renderRow(this.props.data);
        }
    }





    renderRow = (data) => {
        let htm = [];

        var totalSaving = 0;
        var totalShare = 0;

        var ShareMonthSum = 0;
        var totalShareMonth = 0;
        var totalproductSum = 0;
        let juneClose = 0;
        let productSum = 0;

        
        if (Object.keys(data).length > 0) {
        Object.keys(data).forEach(function (year) {


            htm.push(<tr key={year} className="table-info">
                <td colSpan="18" className="text-center"><b>{EnglishToBanglaNumber(year)} সন </b> </td>
            </tr>);

            for (var month = 1; month <= 12; month++) {
                if (data[year][month]) {
                    //  Object.keys(data[year]).forEach(function (month) {
                    let singleMonthDetect = true;
                    var tempShareMonth = Math.floor(ShareMonthSum / 10);
                    totalShareMonth = totalShareMonth + tempShareMonth;
                    productSum = (totalSaving * 30 *8)/36500;
                    totalproductSum = totalproductSum + productSum;
                    let cuClassName = 'table-warning';
                    if (month == 7) {
                        cuClassName = 'table-danger';
                        juneClose = totalSaving + productSum;

                        htm.push(<tr key={year + month} className={'table-warning'}>
                            <td colSpan="5"></td>
                            <td colSpan="4" className="text-primary">{totalShare > 0 && '=' + EnglishToBanglsNumber(tempShareMonth)}</td>
                            <td colSpan="7" className="text-danger">{totalSaving > 0 && '=' + EnglishToBanglsNumber(productSum)}</td>

                        </tr>);
                        htm.push(<tr key={year + month + 1} className={cuClassName}>
                            <td colSpan="9"></td>
                            <td colSpan="7" >{totalShare > 0 && EnglishToBanglsNumber(totalSaving) + '+' + EnglishToBanglsNumber(productSum) + ' = ' + EnglishToBanglsNumber(juneClose)}</td>
                            <td colSpan="2">
                            </td>
                        </tr>);
                        totalSaving = juneClose;

                    } else {
                        htm.push(<tr key={year + month} className={'table-warning'}>
                            <td colSpan="5">{EnglishToBanglamonth(month)}</td>
                            <td colSpan="4" className="text-primary">{totalShare > 0 && '=' + EnglishToBanglsNumber(tempShareMonth)}</td>
                            <td colSpan="7" className="text-danger">{(month !=1 && totalSaving>0) && '=' + EnglishToBanglsNumber(productSum)}</td>

                        </tr>);
                    }

                    ShareMonthSum = 0;
                    productSum = 0;
                    var sumShareCreditAmount = 0;
                    var sumShareDebitAmount = 0;
                    var sumSavingCreditAmount = 0;
                    var sumSavingDebitAmount = 0;




                    Object.keys(data[year][month]).forEach(function (key) {



                        var shareCredit = 0;
                        var shareDebit = 0;
                        var savingCredit = 0;
                        var savingDebit = 0;
                        if (data[year][month][key][0] && data[year][month][key][0].type === 0) {
                            shareCredit = data[year][month][key][0].amount;
                        } else if (data[year][month][key][0] && data[year][month][key][0].type === 1) {
                            shareDebit = data[year][month][key][0].amount;
                        }
                        if (data[year][month][key][1] && data[year][month][key][1].type === 0) {
                            savingCredit = data[year][month][key][1].amount;
                        } else if (data[year][month][key][1] && data[year][month][key][1].type === 1) {
                            savingDebit = data[year][month][key][1].amount;
                        }

                        sumShareCreditAmount = sumShareCreditAmount + shareCredit;

                        sumShareDebitAmount = sumShareDebitAmount + shareDebit;


                        sumSavingCreditAmount = sumSavingCreditAmount + savingCredit;

                        sumSavingDebitAmount = sumSavingDebitAmount + savingDebit;


                        totalShare = totalShare + sumShareDebitAmount - sumShareCreditAmount;

                        ShareMonthSum = totalShare; // share month


                        if (singleMonthDetect && month == 7) {
                            totalSaving = totalSaving + productSum + sumSavingDebitAmount;
                            totalSaving = totalSaving - sumSavingCreditAmount;
                            singleMonthDetect = false;

                        } else {
                            totalSaving = totalSaving + sumSavingDebitAmount - sumSavingCreditAmount;

                        }

                        //  product



                        htm.push(<tr key={year + month + key}>
                            <td>{EnglishToBanglaDate(data[year][month][key][0].date)}</td>
                            <td>{EnglishToBanglaNumber(data[year][month][key][0].memo_no)}</td>
                            <td className="text-danger">{EnglishToBanglsNumber(shareCredit)}</td>
                            <td className="text-success">{EnglishToBanglsNumber(shareDebit)}</td>

                            <td>{EnglishToBanglsNumber(totalShare)}</td>
                            <td></td>
                            <td className="text-danger">{EnglishToBanglsNumber(savingCredit)}</td>
                            <td className="text-success">{EnglishToBanglsNumber(savingDebit)}</td>
                            <td>{EnglishToBanglsNumber(totalSaving)}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>);

                    });


                    productSum = 0;



                    //});

                } else if (month == 6) {
                    let juneProductSum = (totalSaving * 30 *8)/36500;

                 
                    totalproductSum = totalproductSum + juneProductSum;

                    let juneCloseYear = totalSaving + juneProductSum;
                    let shareMonth = Math.floor(totalShare / 10);


                    htm.push(<tr key={year + month} className={'table-warning'}>
                        <td colSpan="5"></td>
                        <td colSpan="4" className="text-primary">{totalShare > 0 && '= ' + EnglishToBanglsNumber(shareMonth)}</td>
                        <td colSpan="7" className="text-danger">{totalShare > 0 && '= ' + EnglishToBanglsNumber(juneProductSum)}</td>

                    </tr>);

                    htm.push(<tr key={year + month + 1} className="text-danger">
                        <td colSpan="9"></td>
                        <td colSpan="7" >{totalSaving > 0 && EnglishToBanglsNumber(totalSaving) + '+' + EnglishToBanglsNumber(juneProductSum) + ' = ' + EnglishToBanglsNumber(juneCloseYear)}</td>
                        <td colSpan="2">
                        </td>

                    </tr>);
                    totalSaving = juneCloseYear;
                    // htm.push(<tr key={year + month} className={'table-warning'}>
                    //     <td colSpan="5">{EnglishToBanglamonth(month)}</td>
                    //     <td colSpan="4" className="text-primary"></td>
                    //     <td colSpan="7" className="text-danger"></td>
                    // </tr>);

                }//end if
            }//end for loop
        });
    }

        this.setState({
            renderRow: htm,
            totalSaving: totalSaving,
            totalShare: totalShare,
            totalShareMonth: totalShareMonth,
            totalproductSum: totalproductSum

        });

    }









    render() {
        return (
            <Col lg="12 lazar_view">
                <Container className="mb-3">
                    <Row>
                        <Col lg={2} md={6}>
                            <Card.Img variant="top" className="rounded " src="/assets/img/humna.jpg" />
                        </Col>
                        <Col lg="10">
                            <p className="mb-0">হিসাব নম্বর  : <b>{this.props.user.member_no && EnglishToBanglaNumber(('000000' + this.props.user.member_no).slice(-5))}   </b></p>
                            <p className="mb-0">নাম : <b> {this.props.user.name && this.props.user.name}</b></p>
                            <p className="mb-0">পিতা / মাতা  : <b> {this.props.user.gardian && this.props.user.gardian}</b></p>
                            <p className="mb-0">ঠিকানা  :  <b> {this.props.user.current_address && this.props.user.current_address}</b></p>
                        </Col>
                    </Row>
                </Container>

                <Table responsive striped bordered hover  >
                    <thead>
                        <tr>
                            <th rowSpan="2">তারিখ </th>
                            <th colSpan="5">শেয়ার </th>
                            <th colSpan="4 ">সেভিং </th>
                            <th colSpan="2">বিবিধ আদায়</th>

                            <th colSpan="4">জামিন সংক্রান্ত বিবরণ </th>

                        </tr>
                        <tr>
                            <th>রশিদের পৃষ্টা নং </th>
                            <th>ফেরত </th>
                            <th>জমা </th>
                            <th>সর্বমোট জমা </th>
                            <th>শেয়ার মাস</th>
                            <th> উত্তোলন</th>
                            <th>জমা </th>
                            <th> সর্বমোট জমা</th>
                            <th>প্রোডাক্ট </th>
                            <th>জরিমানা </th>
                            <th>ভর্তি ফি </th>
                            <th> হিসাব নং</th>
                            <th>ঋণ  নং</th>
                            <th>ঋণের পরিমান</th>
                            <th>ঋণ পরিশোদের  তারিখ</th>
                        </tr>
                    </thead>
                    <tbody>

                        {this.state.renderRow && this.state.renderRow}



                    </tbody>
                    {/* <tfoot>
                        <tr className="table-success">
                            <td colSpan="4">


                            </td>
                            <td>
                                <b >= {EnglishToBanglsNumber(this.state.totalShare)} টাকা </b>
                            </td>
                            <td>
                                <b >={EnglishToBanglsNumber(this.state.totalShareMonth)}</b>
                            </td>
                            <td colSpan="2">



                            </td>
                            <td>
                                <b >= {EnglishToBanglsNumber(this.state.totalSaving)} টাকা </b>
                            </td>
                            <td colSpan="8">
                                <b className="text-danger">= {EnglishToBanglsNumber(this.state.totalproductSum)} টাকা </b>



                            </td>
                        </tr>
                    </tfoot> */}
                </Table>
            </Col>
        )

    }



}

export default PersonalLezarLayout;