import React from "react";

import axios from "axios";

export default class loadHospitals extends React.Component {


    componentDidMount() {
        axios
        .get("https://services1.arcgis.com/Hp6G80Pky0om7QvQ/arcgis/rest/services/Urgent_Care_Facilities/FeatureServer/0/query?where=1%3D1&outFields=*&outSR=4326&f=json")
        .then(res => {
           // console.log(res);

        })
        .catch(err => {

        });

    }

    render() {
        return (

            <h2>
                test hospital
            </h2>

        )
    }


}

