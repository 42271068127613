import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';

import imageReducer from './imageReducer';

import customerReducer from './customerReducer';
import localizedMessageReducer from './localizedMessageReducer';



import collectionReducher from './collectionReducher';

import lezarReducher from './lezarReducher';



export default combineReducers({
    auth: authReducer,
    errors: errorReducer,
    image: imageReducer,
    customer: customerReducer,
    message: localizedMessageReducer,
    collection: collectionReducher,
    lezar: lezarReducher


});