import React, { Component, Children } from 'react';
import { Link }
    from 'react-router-dom';
import { withTranslation }
    from 'react-i18next';

import { connect }
    from 'react-redux';
import Header from '../../common/Header';

import PersonalLezarLayout from './personalLezarLayout'; //1,2


import TermsSavingLezarLayout from './termsSavingLezarLayout';
import DoubleTermsSavingLezarLayout from './doubleTermsSavingLezarLayout';

import EducationLezarLayout from './educationLezarLayout';
import FestivalLezarLayout from './festicalLezarLayout';
import MedicalLezarLayout from './medicalLezarLayout';
import TourLezarLayout from './tourLezarLayout';
import MarrigeLezarLayout from './marrigeLezarLayout';
import MonthlySavingsPlusLayout from './monthlySavingsPlusLaout';//10
import ChildSavingLezarLayout from './childSavingLezarLayout';
import ConsumeLezarLayout from './consumeLezarLayout';
import DailySavingLayout from './dailySavingLayout';//13






import { getViewLazer } from '../../actions/lezarActions';

import { Table, Container, Card, Button, Col, Row } from 'react-bootstrap';



import Spinner from '../../common/Spinner';
import monthlySavingsPlusLaout from './monthlySavingsPlusLaout';

class ViewLezar extends Component {

    constructor(props) {

        super(props);
        this.state = {

            content: ''


        }


    }

    componentDidMount() {

        this.props.getViewLazer(this.props.match.params.product_id, this.props.match.params.account_number, this.props.match.params.user_id);
    }
    componentWillReceiveProps(nextprops) {

        let product_id = parseInt(this.props.match.params.product_id);


        let { view_lazer, loading } = nextprops.lazer;

        console.log(nextprops);

        let content = '';
        if (loading === false && view_lazer !== null) {


            switch (product_id) {
                case 1:
                    content = <PersonalLezarLayout data={view_lazer.data} user={view_lazer.user[0]} />;
                    break;
                case 2:
                    content = <PersonalLezarLayout data={view_lazer.data} user={view_lazer.user[0]} />;
                    break;
                case 3:

                    content = <TermsSavingLezarLayout data={view_lazer.data} user={view_lazer.user[0]} />;
                    break;
                case 4:
                    content = <DoubleTermsSavingLezarLayout data={view_lazer.data} user={view_lazer.user[0]} />;
                    break;
                case 5:
                    content = <EducationLezarLayout data={view_lazer.data} gardian={view_lazer.gardian[0]} child={view_lazer.child[0]} />;
                    break;
                case 6:
                    content = <FestivalLezarLayout data={view_lazer.data} user={view_lazer.user[0]} />;
                    break;
                case 7:
                    content = <MedicalLezarLayout data={view_lazer.data} user={view_lazer.user[0]} />;
                    break;
                case 8:
                    content = <TourLezarLayout data={view_lazer.data} user={view_lazer.user[0]} />;
                    break;
                case 9:
                    content = <MarrigeLezarLayout data={view_lazer.data} gardian={view_lazer.gardian[0]} child={view_lazer.child[0]} />;
                    break;
                case 10:
                    content = <MonthlySavingsPlusLayout data={view_lazer.data} user={view_lazer.user[0]} />;
                    break;
                case 11:
                    content = <ChildSavingLezarLayout data={view_lazer.data} gardian={view_lazer.gardian[0]} child={view_lazer.child[0]} />;
                    break;
                case 12:
                    content = <ConsumeLezarLayout data={view_lazer.data} user={view_lazer.user[0]} />;
                    break;
                case 13:

                    content = <DailySavingLayout data={view_lazer.data} user={view_lazer.user[0]} />;
                    break;
                default:
                    content = <h4 className="text-center text-danger">দুঃখিত: কোনো তথ্য পাওয়া যায়নি </h4>
            }





            this.setState({ content: content, loading: loading });
        }
    }

    render() {
        return (
            <Col lg={12}>
                <Header />
                <Card bg="custom-info" >
                    <Card.Header>


                    </Card.Header>
                    <Card.Body>
                        {this.state.loading && <Spinner />}

                        {this.state.content}


                    </Card.Body>
                </Card>
            </Col>
        )

    }

}

const mapStateToProps = state => ({
    lazer: state.lezar
});


export default withTranslation()(connect(mapStateToProps, { getViewLazer })(ViewLezar));