import React, { Component } from 'react';
import Modal from 'react-modal';
import LocalizedMessage from '../../common/LocalizedMessage';

// import CustomerModal from './CustomerModal';

import { connect }
    from 'react-redux';
import { deleteCustomer }
    from '../../actions/customerActions';
import ConfirmModal from '../../common/ConfirmModal';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
const customStyles = {
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

        padding: '0',
        border: '0',
        background: ''
    }
};

Modal.setAppElement('#root');
Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.5)';
Modal.defaultStyles.overlay.zIndex = '1050';

class ChoiceModal extends Component {
    constructor(props) {
        super(props);


        this.state = {
            modalIsOpen: false,
            customerID: '',
            name: ''
        }
        this.handelRedirect = this.handelRedirect.bind(this);
    
    
    }



   
    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null)
    }


    openModal = (customer) => {
 console.log(customer);


        this.setState({ modalIsOpen: true, customer: customer });
        //  customerID !== null && this.props.getSingleCustomer(customerID);
    }
    deleteCustomer = customerID => {


        // this.props.deleteCustomer(customerID);
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    deleteItem = () => {
        this.props.deleteFunction();
        this.closeModal();
    }
    handelRedirect = (member_no)=>{
       this.props.handleRedirect(member_no);


     //   this.setState({member_no:member_no});

       // this.props.history.push('/customer/add/'+member_no);
    }

 
    render() {
        return (
            <div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div id="choiceModel">
                        <div className="modal-dialog modal-animations">
                            <div className="modal-content pl-4 pr-4 pb-4">
                                <div className="modal-header">
                                    {/* <strong className="modal-title"><LocalizedMessage messageKey="button.delete " /></strong> */}
                                    <h5>{this.state.customer && this.state.customer.name}</h5>
                                    <button type="button" className="close" onClick={this.closeModal}>×</button>
                                </div>
                                <div className="modal-body">


                                    <div className="row mt-3">
                                        <div className="col-lg-6 col-sm-6">
                                            <Button type="button" className="btn  btn-info btn-block" onClick={()=>{this.handelRedirect(this.state.customer.id)}}  ><LocalizedMessage messageKey="button.edit" /></Button>

                                        </div>
                                        <div className="col-lg-6 col-sm-6">
                                            <button type="button" className="btn btn-danger btn-block" onClick={() => { this.confirm.openModal(); this.closeModal() }} ><LocalizedMessage messageKey="button.delete" /></button>

                                        </div>
                                    </div>


                                    <div className="row mt-3">
                                        <div className="col-lg-3">

                                        </div>
                                        <div className="col-lg-6">
                                            <button type="button" className="btn cu-btn-dark btn-block" onClick={this.closeModal}><LocalizedMessage messageKey="button.cancel" /></button>

                                        </div>
                                        <div className="col-lg-3">

                                        </div>


                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </Modal>
                {/* <CustomerModal onRef={ref => this.customer = ref} getAllCustomers={() => this.props.getAllCustomers()} /> */}
                <ConfirmModal onRef={ref => this.confirm = ref} deleteFunction={() => this.deleteCustomer(this.state.customerID)} />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    customer: state.customer
})

export default connect(mapStateToProps, { deleteCustomer }
)(ChoiceModal);