import React from 'react';
import logo from './logo.svg';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Dashboard from './components/dashBoard/DashBoard';

import AddCollection from './components/collection/AddCollection';
import Collection from './components/collection/Collection';

import ViewCollection from './components/collection/ViewCollection';

import Expense from './components/expense/expense';
import Customer from './components/customer/customer';
import CustomerAdd from './components/customer/CustomerAdd';






import getHospital from './getHospital';

import Lezar from './components/lezar/lezar';

import DetailsLezar from './components/lezar/detailsLezar';

import PrivateRoute from './common/PrivateRoute';
import { Provider } from 'react-redux';
import Login from './components/Login/Login';
import store from './store';

import { setCurrentUser } from './actions/authActions';

if (localStorage.headers) {
    store.dispatch(setCurrentUser(localStorage.headers));
}




function App() {
    return (
        <Provider store={store}>
            <Router>
                <Route exact path='/' component={Login}></Route>
                <Route exact path='/hospital' component={getHospital}></Route>
                <PrivateRoute exact path='/dashboard' component={Dashboard}></PrivateRoute>
                <PrivateRoute exact path='/collection' component={Collection}></PrivateRoute>
                <PrivateRoute exact path='/addcollection' component={AddCollection}></PrivateRoute>
                <PrivateRoute exact path='/collection/:collection_id' component={ViewCollection}></PrivateRoute>
                <PrivateRoute exact path='/expense' component={Expense}></PrivateRoute>
                <PrivateRoute exact path='/lezar' component={Lezar}></PrivateRoute>
                <PrivateRoute exact path='/lezar/:product_id/:account_number/:user_id' component={DetailsLezar}></PrivateRoute>
                <PrivateRoute exact path='/customer' component={Customer}></PrivateRoute>
                <PrivateRoute exact path='/customer/add/:id?' component={CustomerAdd}></PrivateRoute>
            </Router>
        </Provider>
    );
}

export default App;
