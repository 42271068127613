//Login User Constants
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
//Loading Constants
export const LOADING = 'LOADING';
//Errors Constants
export const GET_ERRORS = 'GET_ERRORS';
//Category Constants
export const GET_TOP_LEVEL_CATEGORIES = 'GET_TOP_LEVEL_CATEGORIES';
export const GET_CHILD_LEVEL_CATEGORIES = 'GET_CHILD_LEVEL_CATEGORIES';
export const GET_CATEGORY_HIERARCHY = 'GET_CATEGORY_HIERARCHY';
//Product Constants
export const GET_TOP_LEVEL_PRODUCTS = 'GET_TOP_LEVEL_PRODUCTS';
export const GET_CHILD_LEVEL_PRODUCTS = 'GET_CHILD_LEVEL_PRODUCTS';
export const GET_PRODUCTS_BY_CATEGORY = 'GET_PRODUCTS_BY_CATEGORY';
export const GET_PRODUCT_HIERARCHY = 'GET_PRODUCT_HIERARCHY';
export const GET_PRODUCT = 'GET_PRODUCT';
//User Settings Constants
export const GET_TIME_SETTINGS = 'GET_TIME_SETTINGS';
export const GET_USER_SETTINGS = 'GET_USER_SETTINGS';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS';
//Image Object
export const GET_IMAGE_OBJECT = 'GET_IMAGE_OBJECT';
//Product Configuration
export const GET_PRODUCT_CONFIGURATION_BY_ARTICLE = 'GET_PRODUCT_CONFIGURATION_BY_ARTICLE';
export const GET_AVAILABLE_COMPONENTS = 'GET_AVAILABLE_COMPONENTS';
export const GET_AVAILABLE_ARTICLES = 'GET_AVAILABLE_ARTICLES';
export const GET_ALL_PRODUCT_CONFIGURATIONS = 'GET_ALL_PRODUCT_CONFIGURATIONS';
export const GET_SINGLE_PRODUCT_CONFIGURATION = 'GET_SINGLE_PRODUCT_CONFIGURATION';
export const SAVE_PRODUCT_CONFIGURATION = 'SAVE_PRODUCT_CONFIGURATION';
export const UPDATE_PRODUCT_CONFIGURATION = 'UPDATE_PRODUCT_CONFIGURATION';
export const DELETE_PRODUCT_CONFIGURATION = 'DELETE_PRODUCT_CONFIGURATION';
//Customer Management
export const VALIDATE_CUSTOMER_NUMBER = 'VALIDATE_CUSTOMER_NUMBER';
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const GET_ALL_CUSTOMERS = 'GET_ALL_CUSTOMERS';
export const GET_SINGLE_CUSTOMER = 'GET_SINGLE_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
//Localized Messages
export const GET_LOCALIZED_MESSAGES = 'GET_LOCALIZED_MESSAGES';

export const GET_SINGLE_QUOTE = 'GET_SINGLE_QUOTE';
export const GET_ALL_QUOTES = 'GET_ALL_QUOTES';

export const INTQUOTE = 'INTQUOTE';



export const GET_USER_BY_ID = 'GET_USER_BY_ID';

export const CHECK_USER_BY_ID = 'CHECK_USER_BY_ID';

export const CUSTOMER_SEARCH = 'CUSTOMER_SEARCH';

export const CUSTOMER_REGISTRATION = 'CUSTOMER_REGISTRATION';


export const INT_REGISTRATION = 'INT_REGISTRATION';

export const SAVE_COLLECTION = 'SAVE_COLLECTION';
export const DELETE_USER_BY_ID = 'DELETE_USER_BY_ID';

export const INT_COLLECTION = 'INT_COLLECTION';

export const COLLECTION_BY_MONTH = 'COLLECTION_BY_MONTH';


export const VIEW_COLLECTION = 'VIEW_COLLECTION';


export const VIEW_LAZER = 'VIEW_LAZER';
















