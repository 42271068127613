/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import React, { Component }
    from 'react';
import { Link }
    from 'react-router-dom';

import { connect }
    from 'react-redux';
import { withTranslation }
    from 'react-i18next';

import Header from '../../common/Header';


import LocalizedMessage from '../../common/LocalizedMessage';
import { Form, Row, Table, Col, Container, Breadcrumb, Card, Button, Input, InputGroup, FormControl }
    from 'react-bootstrap';
import { CheckUserById } from '../../actions/collectionActions';
import SaInput from '../../common/SaInput';
import ConfirmCustomerModal from '../customer/ConfirmCustomerModal';


import SaButtonInputView from '../../common/SaButtonInputView';

const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
var convertToBangla = require('number-translate-bangla');




var EnglishToBanglsNumber = require('../../common/EnglishToBanglsNumber');



class Lezer extends Component {

    constructor() {
        super();

        var date = (new Date()).toLocaleDateString('en-US', DATE_OPTIONS);

        this.state = {
            next: '',
            customer: [],
            data: [],
            products: [],
            account_number: '',
            memoNo: '',
            sheetNo: '',
            date: date,
            share: '',
            saving: '',
            CustomerNoShow: true,
            collectionTypeShow: false,
            CheckShow: false,
            inputFiled: {
                share: 0,
                saving: 0
            }
        }

    }
    componentWillUpdate() {
        console.log('component did update');
    }


    componentDidMount() {
        console.log('component did mount');

        if (this.state.products.length > 0) {
            this.setState({
                CustomerNoShow: false,
                collectionTypeShow: true

            });

        }
    }

    checkCustomerNo = () => {
        if (this.state.account_number !== '') {
            this.props.CheckUserById(this.state.account_number);

        } else {
            this.setState({ CustomerNoShow: false, anotherCollection: true });

        }
    }

    CheckData = () => {

        this.setState({ collectionTypeShow: false, CheckShow: true });

    }
    saveData = () => {


        //console.log('save data');
    }

    cancelCollection = () => {
        this.setState({ collectionTypeShow: false, CustomerNoShow: true });
    }

    cancelCheck = () => {
        this.setState({ collectionTypeShow: true, CheckShow: false });
    }
    handelAddcustomer = () => {
        this.props.history.push('/customer/add/');
    }


    handelCancelcustomer = () => {

        window.location.reload();
    }
    componentWillReceiveProps(nextProps) {
      //  console.log('nextProps');

       // console.log(nextProps);

        const { check_user_by_id, loading } = nextProps.get_user_by_id;

        // var customer = nextProps.get_user_by_id;


        if (loading === false && check_user_by_id && check_user_by_id !== null) {
            this.setState({
                customer: check_user_by_id.customer,
                data: check_user_by_id.data,
                products: check_user_by_id.products,
                CustomerNoShow: false,
                collectionTypeShow: true

            });

            // console.log(customer.get_user_by_id.data);
        } else if (nextProps.errors.errors && nextProps.errors.errors !== null && nextProps.errors.errors.error === 'invalid_grant') {
            this.ConfirmCustomerModal.openModal();
            //  console.log('invalid grant');
        }

    }

    detailslezar = (product_id, account_number) => {



        //  this.props.history.push('/lezar/' + product_id + '/' + account_number)
        //   this.setState({ collectionTypeShow: false, CheckShow: true });



        window.open(
            '/lezar/' + product_id + '/' + account_number + '/' + this.state.account_number,
            '_blank' // <- This is what makes it open in a new window.
        );




    }

    renderAccounts = () => {
        var data = this.state.data && this.state.data;
        let products = this.state.products && this.state.products;
        let html = [];


        if (this.state.products.length > 0 && this.state.data.length > 0) {



            data.map((singleaccount, index) => {

                //    console.log(singleaccount.product_id);
                var product_id = singleaccount.product_id;

                html.push(
                    <Col lg={3} md={3} key={index}>
                        <SaButtonInputView detailslezar={() => this.detailslezar(product_id, singleaccount.account_number)} isOpen="1" inputFiled={this.state.inputFiled} readOnly={false} value={products[product_id - 1] && products[singleaccount.product_id - 1].title} type="number" accountNo={singleaccount.account_number} setState={s => this.setState(s)} name={singleaccount.product_id && singleaccount.product_id} label={products[product_id - 1] && products[product_id - 1].title} />

                    </Col>
                )
            }
            )


        }

        return html;
    }




    handleChange = (e) => {


    }


    render() {
        return (
            <Col lg={12}>
                <Header />
                <Container className="mt-5">


                    <Card bg="custom-info" >
                        <Card.Header>

                            {this.state.CustomerNoShow && <h4 className="text-center font-weight-bold mt-4">  <LocalizedMessage messageKey="menu.lezar" /> </h4>}




                            {this.state.collectionTypeShow && <h4 className="text-center mt-4"><LocalizedMessage messageKey="collection.types" /></h4>}

                            {this.state.CheckShow && (<h4 className="text-center mt-4">ব্যক্তিগত খতিয়ান</h4>)}





                        </Card.Header>
                        <Card.Body>
                            <div className="steps-form mt-1 mb-4">
                                <div className="steps-row setup-panel">
                                    <div className="steps-step">
                                        <button type="button" className={this.state.CustomerNoShow ? ' btn btn-circle waves-effect waves-light btn-indigo' : ' btn btn-circle waves-effect waves-light   btn-info'}> 1</button>

                                    </div>
                                    <div className="steps-step">
                                        <button type="button" className={this.state.collectionTypeShow ? ' btn btn-circle waves-effect waves-light btn-indigo' : ' btn btn-circle waves-effect waves-light  btn-info'} >2</button>

                                    </div>

                                </div>
                            </div>

                            {this.state.CustomerNoShow &&
                                <Row>
                                    <h4 className="text-center"><LocalizedMessage messageKey="collection.customerNo" /></h4>
                                    <Col lg={3}>

                                    </Col>
                                    <Col lg={6} className="mt-2 mb-5">
                                        <SaInput handleChange={this.handleChange} autoFocus={true} readOnly={false} type="number" value={this.state.account_number} setState={s => this.setState(s)} name="account_number" label={< LocalizedMessage messageKey="customer.customerNo" />} />


                                    </Col>
                                    <Col lg={3}>

                                    </Col>
                                </Row>
                            }
                            {this.state.collectionTypeShow &&
                                <Col lg={12}>


                                    <Row>




                                        {this.renderAccounts()}
                                        <Col lg={2} md={3}>

                                            <Card>
                                                <Card.Header>
                                                    <Link to="/customer" className="btn btn-outline-info" >আরো হিসাব যুক্ত করুন </Link>
                                                </Card.Header>

                                            </Card>

                                        </Col>

                                    </Row>
                                </Col>
                            }
                          


                        </Card.Body>
                        <Card.Footer>
                            <Row>
                                <Col lg="6" md={6} xs={6}>


                                    {this.state.collectionTypeShow &&
                                        <button onClick={this.cancelCollection} className="btn  btn-rounded prevBtn  waves-effect waves-light" type="button"> <LocalizedMessage messageKey="button.cancel" /></button>
                                    }
                                    {this.state.CheckShow &&
                                        <button onClick={this.cancelCheck} className="btn  btn-rounded prevBtn  waves-effect waves-light" type="button"> <LocalizedMessage messageKey="button.cancel" /></button>
                                    }



                                </Col>
                                <Col lg="6" md={6} xs={6}>
                                    {this.state.CustomerNoShow &&
                                        <button onClick={this.checkCustomerNo} className="btn btn-indigo btn-rounded nextBtn float-right waves-effect waves-light" type="button"> <LocalizedMessage messageKey="button.next" /></button>
                                    }




                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>

                </Container>




                <ConfirmCustomerModal handelCancelcustomer={this.handelCancelcustomer} handelAddcustomer={this.handelAddcustomer} onRef={ref => this.ConfirmCustomerModal = ref} />

            </Col>


        )
    }

}
const mapStateToProps = state => ({
    get_user_by_id: state.collection,
    errors: state.errors
});


export default withTranslation()(connect(mapStateToProps, { CheckUserById, EnglishToBanglsNumber })(Lezer));