/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import React, { Component }
    from 'react';
import { Link }
    from 'react-router-dom';

import { connect }
    from 'react-redux';
import { withTranslation }
    from 'react-i18next';

import Header from '../../common/Header';
import { getViewCollection } from '../../actions/collectionActions';

import LocalizedMessage from '../../common/LocalizedMessage';
import { Table, Form, Row, Col, Container, Breadcrumb, Card, Button, Input, InputGroup, FormControl }
    from 'react-bootstrap';

import SaInput from '../../common/SaInput';

import SaDateInput from '../../common/SaDateInput';

import SaButtonInput from '../../common/SaButtonInput';
import ConfirmCustomerModal from './ConfirmCustomerModal';
import ChengeHistoryModal from './chengeHistoryModal';

const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
var convertToBangla = require('number-translate-bangla');



var EnglishToBanglsNumber = require('../../common/EnglishToBanglsNumber');

var EnglishToBanglaNumber = require('../../common/EnglishToBanglaNumber');


var EnglishToBanglamonth = require('../../common/EnglishToBanglamonth');




class ViewCollection extends Component {

    constructor() {
        super();

        var date = (new Date()).toLocaleDateString('en-US', DATE_OPTIONS);

        this.state = {

            view_collection: [],
            grandTotal: 0.0,
            year : '',
            month : ''


        }



    }

    componentDidMount() {

        // console.log(this.props.match.params.collection_id);


        this.props.getViewCollection(this.props.match.params.collection_id);
    }


    saveData = () => {


        // console.log('save data');
    }

    viewhistory = () => {
        this.history.openModal();
    }



    componentWillReceiveProps(nextProps) {
        let { loading, view_collection } = nextProps.collection;

        if (loading === false && view_collection && view_collection !== null) {
            this.setState({ view_collection: view_collection.collection });
            this.setState({ products: view_collection.products });
            let table = this.renderCollection(view_collection.collection, view_collection.products);

         //   console.log('table test');

        //    console.log(table);

            this.setState({ collectionTable: table });
        }
    }

    renderProducts = () => {
        var products = [];


        this.state.products && this.state.products.map((product, index) => {
            products.push(<th key={index}>{product.title}</th>);

        });
        return products;


    }

    renderCollection = (view_collection, products) => {
        //console.log('render collection');

        var productlength = products.length;
        var options = {
            year: "numeric",
            month: "2-digit",
            day: "numeric"
        };
        var arr = [];
        let grandTotal = 0.0;
        var year = '';
        var month = '';
        Object.keys(view_collection).forEach(function (key) { //key memo no 
            var products = [];
            var date = '';
            var name = '';
            var user_id = '';
            let toltalCollection = 0;
            for (var x = 1; x < productlength + 1; x++) {
                if (view_collection[key].hasOwnProperty(x)) {

                    date = view_collection[key][x][0]['date'].split("-");
                    user_id = view_collection[key][x][0]['user_id'];
                    name = view_collection[key][x][0]['member']['name'];

                    toltalCollection += view_collection[key][x][0]['amount'];

                    let count = view_collection[key][x].length;

                    if (count > 1) {
                        let inner_amount = [];
                        view_collection[key][x].map((collection, index) => {

                            console.log(collection);

                            inner_amount.push(
                                collection.amount>0 &&

                             <p key={index} className="mb-0 col-lg-12 "  style={{display:'inline'}}> <b>{EnglishToBanglaNumber(collection.account_number)} </b> : <small>  {EnglishToBanglsNumber(collection.amount)} </small> </p> 

                            );
                        });

                        products.push(

                            <td key={x}><Row>{inner_amount} </Row></td>
                        )
                    } else {
                        products.push(

                            <td key={x}>{EnglishToBanglsNumber(view_collection[key][x][0]['amount'])}</td>
                        )
                    }

                } else {
                    products.push(

                        <td key={x}></td>
                    )
                }

            }

            var n = new Date(date[0] + '-' + date[1] + '-' + date[2]);
            year = date[0];
            month = date[1];
            arr.push(<tr key={key}>
                <td>{n.toLocaleString("bn-BD", options)}</td>
                <td>
                    {EnglishToBanglaNumber(key)}
                </td>
                <td>{EnglishToBanglaNumber(user_id)}</td>
                <td>{name}</td>


                {products}
                <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td>৳ {EnglishToBanglsNumber(toltalCollection)} </td>
            </tr>);
            grandTotal += toltalCollection;

        });
        var productsth = [];


        products && products.map((product, index) => {
            productsth.push(<th key={index}>{product.title}</th>);

        });

        this.setState({year : EnglishToBanglaNumber(year)});
        this.setState({month : EnglishToBanglamonth( parseInt(month))});



        return (<Table className="text-center" striped bordered hover size="sm " responsive>
            <thead>
                <tr>
                    <th>তারিখ </th>
                    <th>রশিদ নং</th>
                    <th>সদস্য নম্বর</th>
                    <th>সদস্য /সদস্যার নাম </th>
                    {productsth}
                    <th>ঋণ নং</th>
                    <th>আসল </th>
                    <th>সেবা মূল্য </th>
                    <th>কিস্তি নং </th>
                    <th>জরিমানা </th>
                    <th>ফর্ম/ পাশ বই বিক্রয় </th>
                    <th>তহবিল </th>
                    <th>সর্বমোট আদায় </th>
                </tr>
            </thead>
            <tbody>
                {arr}
            </tbody>
            <tfoot>
                <tr>
                    <td>মোট </td>
                    <td colSpan="23">

                    </td>
                    <td>৳ {EnglishToBanglsNumber(grandTotal)}</td>
                </tr>
            </tfoot>
        </Table>)

    }





    render() {
        return (
            <Col lg={12}>
                <Header />


                <Card bg="custom-info" >
                    <Card.Header>

                        <Row>
                            <Col lg="2" md="2">
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <Button variant="outline-info">মাস </Button>
                                    </InputGroup.Prepend>
                                    <FormControl disabled={true} defaultValue={this.state.month} aria-describedby="basic-addon1" />
                                </InputGroup>

                            </Col>
                            <Col lg="8" md="8">

                                <h4 className="text-center font-weight-bold mt-4">  <LocalizedMessage messageKey="collection.title" /> </h4>

                            </Col>
                            <Col lg="2" md="2">
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <Button variant="outline-info">পৃষ্টা নং  </Button>
                                    </InputGroup.Prepend>
                                    <FormControl disabled={true} defaultValue={EnglishToBanglaNumber(this.props.match.params.collection_id)} aria-describedby="basic-addon1" />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <Button variant="outline-info">সাল  </Button>
                                    </InputGroup.Prepend>
                                    <FormControl disabled={true} defaultValue={this.state.year} aria-describedby="basic-addon1" />
                                </InputGroup>
                            </Col>
                        </Row>


                    </Card.Header>
                    <Card.Body>
                        <Row>
                            {this.state.collectionTable && this.state.collectionTable}

                        </Row>


                    </Card.Body>
                    <Card.Footer>
                        <Row>
                            <Col lg="6" md={6} xs={6}>




                            </Col>
                            <Col lg="6" md={6} xs={6}>


                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>


                <ConfirmCustomerModal onRef={ref => this.selectcustomer = ref} />

                <ChengeHistoryModal onRef={ref => this.history = ref} />





            </Col>


        )
    }

}
const mapStateToProps = state => ({
    collection: state.collection,
    errors: state.errors
});


export default withTranslation()(connect(mapStateToProps, { getViewCollection, EnglishToBanglsNumber })(ViewCollection));