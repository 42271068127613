/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import React, { Component }
    from 'react';
import { Link }
    from 'react-router-dom';

import { connect }
    from 'react-redux';
import { withTranslation }
    from 'react-i18next';

import Header from '../../common/Header';
import { intRegistration, checkCustomer, deleteUserById, saveCustomer } from '../../actions/customerActions';

import LocalizedMessage from '../../common/LocalizedMessage';
import { Accordion, Card, Button, Image, Form, Row, Col, Container, Breadcrumb, Input, InputGroup, FormControl }
    from 'react-bootstrap';

import SaInput from '../../common/SaInput';

import SaButtonNext from '../../common/SaButtonNext';

import SaAddAccountInput from '../../common/SaAddAccountInput';
import ConfirmCustomerModal from './ConfirmCustomerModal';
import SaAddViewAccountInput from '../../common/SaAddViewAccountInput';
// import NominiModal from './NominiModal';

const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };




var EnglishToBanglsNumber = require('../../common/EnglishToBanglsNumber');





class CustomerAdd extends Component {

    constructor() {
        super();

        var date = (new Date()).toLocaleDateString('en-US', DATE_OPTIONS);

        this.state = {
            next: '',
            customer: [],
            data: [],
            member_no: '',
            memoNo: '',
            sheetNo: '',
            date: date,
            CustomerNoShow: true,
            collectionTypeShow: false,
            CheckShow: false,
            profileFile: '/assets/img/humna.jpg',
            profileFileSrc: null,
            signFile: '/assets/img/sign.jpg',
            signFileSrc: null,
            inputFiled: [],
            disabled: 1,
            registrationClick: false,
            date_of_bath: '0-00-0000'
        }

        //this.inputProfileImage = React.createRef();
        this.ChengeProfileInput = React.createRef();

        this.ChengeSignInput = React.createRef();
        this.registrationForm = React.createRef();
        this.customerNobutton = React.createRef();


        this.AccountsForm = React.createRef();


    }

    chengeFocus = () => {

        this.setState({ focus: true });
    }




    componentWillUnmount() {
        //  console.log('component will unmount');
        this.setState({
            name: '',
            email: '',
            password: '',
            member_no: 0,

            national_id: 0,

            gardian: '',
            mother_name: '',
            phone_number: '',
            profession: '',
            date_of_bath: '2018-07-22',
            // formData.append('nomini', this.state.customerNomini);

            village_id: '',
            zila_id: '',
            thana_id: '',
            post_id: '',
            nationality_id: '',
            religious_id: '',
            current_address: '',
            CustomerNoShow: true,
            collectionTypeShow: false,
        });

        this.props.deleteUserById();
    }

    componentDidMount() {
        this.props.intRegistration();

        if (this.props.match.params.id) {
            this.setState({ member_no: this.props.match.params.id, disabled: 0 });


            //    this.customerNobutton.current.click();
        }
    }
    checkCustomerNo = () => { // setp 1

        var customerNo = this.state.member_no;

        delete this.state;



        if (customerNo !== '') {
            this.props.checkCustomer(2, customerNo);


        } else {
            this.props.checkCustomer(2.0);

        }
        this.setState({ disabled: 1, registrationClick: false });


    }

    CheckData = () => {

        if (this.AccountsForm.current.reportValidity()) {
            this.setState({ collectionTypeShow: false, CheckShow: true, registrationClick: false });
        }
        //console.log('save CheckData');
    }
    saveData = () => {
        // console.log('saved data');

        // console.log(this.state.inputFiled);

        var formData = new FormData();

        formData.append('name', this.state.name);


        formData.append('email', this.state.email);

        formData.append('password', this.state.member_no);

        formData.append('role', 3);

        formData.append('member_no', this.state.member_no);

        formData.append('national_id', this.state.national_id);


        formData.append('gardian', this.state.gardian);
        formData.append('mother_name', this.state.mother_name);

        formData.append('phone_number', this.state.phone_number);
        formData.append('profession', this.state.profession);
        formData.append('date_of_bath', this.state.date_of_bath);




        // formData.append('nomini', this.state.customerNomini);

        formData.append('village_id', this.state.village_id);
        formData.append('zila_id', this.state.zila_id);
        formData.append('thana_id', this.state.thana_id);
        formData.append('post_id', this.state.post_id);



        formData.append('nationality_id', this.state.nationality_id);
        formData.append('religious_id', this.state.religious_id);
        formData.append('current_address', this.state.current_address);





        formData.append('inputFiled', JSON.stringify(this.state.inputFiled));
        formData.append('profileFileSrc', this.state.profileFileSrc);
        formData.append('signFileSrc', this.state.signFileSrc);

        console.log(this.state.inputFiled);

        this.props.saveCustomer(formData);

        this.setState({ registrationClick: true });

    }

    cancelCollection = () => {
        this.setState({ collectionTypeShow: false, registrationShow: true });
    }

    cancelCheck = () => {
        this.setState({ collectionTypeShow: true, CheckShow: false });
    }



    cancelRegistration = () => {

        this.setState({ CustomerNoShow: true, registrationShow: false });


    }

    registrationFunc = () => {
        if (this.registrationForm.current.reportValidity()) {
            this.setState({ registrationShow: false, collectionTypeShow: true, registrationClick: false });
        }
    }




    componentWillReceiveProps(nextProps) {




        let { get_user_by_id, int_registration, customer_registration, loading } = nextProps.customer

        //   const { dispatch } = this.props;
        // console.log('dispatch');
        // console.log(nextProps);

        if (loading === false && int_registration && int_registration !== null) {

            this.setState({
                all_thana: int_registration.thana,
                all_zila: int_registration.zila,
                all_post: int_registration.post,
                all_village: int_registration.village,
                products: int_registration.products,
            });

        }



        if (loading === false && get_user_by_id && get_user_by_id !== null && this.state.registrationClick === false) { ///go next if avaiable customer

            let type = get_user_by_id.type;
            if (type === 2) {//no user found 


                let inputObj = [];
                inputObj.push({
                    account_number: this.state.member_no,
                    product_id: 1
                });

                inputObj.push(
                    {
                        account_number: this.state.member_no,
                        product_id: 2
                    }
                );


                this.setState({
                    inputFiled: inputObj,
                    name: '',
                    date_of_bath: '',
                    email: '',
                    role: '',
                    phone_number: '',
                    nomini: '',
                    village_id: '',
                    post_id: '',
                    thana_id: '',
                    zila_id: '',
                    nationality_id: '',
                    religious_id: '',
                    gardian: '',
                    mother_name: '',
                    profession: 1,
                    current_address: '',
                    national_id: '',
                    CustomerNoShow: false,
                    registrationShow: true,
                    disabled: 0
                });

            } else if (type === 1) {// product assign but ready to assign more products
                let single_customer = get_user_by_id.customer;
                this.setState({
                    id: single_customer.id,
                    name: single_customer.name,
                    date_of_bath: single_customer.date_of_bath,
                    email: single_customer.email,
                    role: single_customer.role,
                    member_no: single_customer.member_no,
                    phone_number: single_customer.phone_number,
                    nomini: single_customer.nomini,
                    village_id: single_customer.village_id,
                    post_id: single_customer.post_id,
                    thana_id: single_customer.thana_id,
                    zila_id: single_customer.zila_id,
                    nationality_id: single_customer.nationality_id,
                    religious_id: single_customer.religious_id,
                    gardian: single_customer.gardian,
                    mother_name: single_customer.mother_name,
                    profession: single_customer.profession,
                    current_address: single_customer.current_address,
                    national_id: single_customer.national_id,
                    CustomerNoShow: false,
                    registrationShow: true,
                    disabled: 0,

                });

                if (get_user_by_id.data.length === 0) {
                    let inputObj = [];
                    inputObj.push({
                        account_number: this.state.member_no,
                        product_id: 1
                    });

                    inputObj.push(
                        {
                            account_number: this.state.member_no,
                            product_id: 2
                        }
                    );


                    this.setState({
                        inputFiled: inputObj
                    });
                } else {
                    this.setState({ inputFiled: get_user_by_id.data });
                }





            }




        } else if (nextProps.errors.errors && nextProps.errors.errors.error === 'invalid_grant') {


        }
        if (loading === false && customer_registration && customer_registration !== null && this.state.registrationClick) {
            this.props.history.push('/customer');

            // this.props.history
        }




    }


    renderAccounts = () => {
        let html = [];
        let products = this.state.products;
        if (this.state.inputFiled && this.state.inputFiled.length > 0) {
            this.state.inputFiled.map((singleaccount, index) => {
                let isOpen = "0";
                if (this.state.inputFiled && products[singleaccount.product_id]) {
                    isOpen = "1";
                }
                html.push(<Col xl={3} lg={4} key={index}>
                    {singleaccount.product_id === 1 || singleaccount.product_id === 2 ?
                        <SaAddAccountInput handelClose={this.handelClose} isOpen="1" inputFiled={this.state.inputFiled} readOnly={true} label={products[singleaccount.product_id - 1] && products[singleaccount.product_id - 1].title} type="number" setState={s => this.setState(s)} product_id={singleaccount.product_id} name={index} value={this.state.member_no} />
                        :
                        <SaAddAccountInput handelClose={this.handelClose} isOpen="1" inputFiled={this.state.inputFiled} readOnly={false} label={products[singleaccount.product_id - 1] && products[singleaccount.product_id - 1].title} type="number" setState={s => this.setState(s)} product_id={singleaccount.product_id} name={index} value={singleaccount.account_number && singleaccount.account_number} />
                    }
                </Col>);
            }
            )
        }
        return html;
    }

    handleChangeSelectProduct = (e) => {
        if (e.target.value !== '0') {
            let inputFiled = {
                account_number: '',
                product_id: parseInt(e.target.value)
            };
            this.setState({
                inputFiled: [...this.state.inputFiled, inputFiled]
            });

        }
        e.target.value = 0;
        return 0;
    }

    handelClose = (index) => {

        console.log(index)

        let oldinputObj = this.state.inputFiled;

        let clone = Object.assign({}, oldinputObj);

        delete clone[index];
        let newinputObj = Object.values(clone);

        this.setState({ inputFiled: newinputObj });
    }


    renderSumarizedAccounts = () => {
        var inputedFileds = this.state.inputFiled;
        var products = this.state.products;

        if (inputedFileds.length > 0) {
            return (inputedFileds.map((singleaccount, index) => (
                singleaccount.account_number > 0 &&

                <Col md={6} sm={6} xs={12} xl={3} lg={4} key={index}>


                    <SaAddViewAccountInput inputFiled={this.state.inputFiled} label={products[singleaccount.product_id - 1] && products[singleaccount.product_id - 1].title} product_id={singleaccount.product_id} name={index} />
                </Col>
                // <Col md="6" key={index}>
                //     <InputGroup className="mb-3">
                //         <InputGroup.Prepend>
                //             <Button className="equal-width" variant="info">{products[singleaccount.product_id - 1].title}</Button>
                //         </InputGroup.Prepend>
                //         <FormControl disabled={true} defaultValue={singleaccount.account_number} aria-describedby="basic-addon1" />
                //     </InputGroup>
                // </Col>

            ))
            );

        }

    }

    sum = (obj) => {
        var sum = 0;
        for (var el in obj) {
            if (obj.hasOwnProperty(el)) {
                sum += parseFloat(obj[el]);
            }
        }
        return sum;
    }

    // ChengePhotoButton (){
    //     this.inputProfileImage.current.click();
    // }

    ChengeProfilePhotofunc = () => {
        this.ChengeProfileInput.current.click();

    }

    handleProfileChange = (event) => {
        this.setState({
            profileFile: URL.createObjectURL(event.target.files[0]),
            profileFileSrc: event.target.files[0]
        })
    }

    ChengeSignPhotofunc = () => {
        this.ChengeSignInput.current.click();
    }

    handleSignChange = (event) => {
        this.setState({
            signFile: URL.createObjectURL(event.target.files[0]),
            signFileSrc: event.target.files[0]
        })
    }

    handleChangeNo = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        this.setState({ [name]: value });
        //  this.registrationForm.current.reportValidity();
        if (value.length > 0) {
            this.setState({ disabled: 0 })
        } else {
            this.setState({ disabled: 1 })
        }
    }

    handleChange = (name, value) => {
        console.log(name + '=' + value)


    }
    handleChangeDate = (e) => {
        console.log(e.target.name + '=' + e.target.value)

        this.setState({ [e.target.name]: e.target.value });

    }
    handleChangeSelect = (e) => {

        var name = e.target.name;

        var value = e.target.value;
        this.setState({ [name]: value });


        // if (this.registrationForm.current.reportValidity()) {
        //     this.setState({ disabled: 0 });
        // }



    }

    renderPost() {
        if (this.state.all_post && this.state.all_post.length > 0) {

            return (this.state.all_post.map((post, index) => (
                <option key={index} value={post.id}>{post.post_name}</option>

            )
            ));

        }

    }
    renderThana() {
        if (this.state.all_thana && this.state.all_thana.length > 0) {

            return (this.state.all_thana.map((thana, index) => (
                <option key={index} value={thana.id}>{thana.thana_name}</option>

            )
            ));

        }
    }
    renderZila() {
        if (this.state.all_zila && this.state.all_zila.length > 0) {

            return (this.state.all_zila.map((zila, index) => (
                <option key={index} value={zila.id}>{zila.zila_name}</option>

            )
            ));

        }
    }
    renderVillage() {
        if (this.state.all_village && this.state.all_village.length > 0) {
            return (this.state.all_village.map((village, index) => (
                <option key={index} value={village.id}>{village.village}</option>
            )
            ));
        }
    }

    renderProducts() {
        if (this.state.products && this.state.products.length > 0) {
            return (this.state.products.map((products, index) => (
                <option key={index} value={products.id}>{products.title}</option>
            )
            ));
        }
    }




    //    totalAmountRender = () => {
    //        let totalAmount =0;
    //
    //        var inputedFiled = this.state.inputFiled;
    //        
    //         
    //                   
    //            console.log(inputedFiled);
    //       
    //        
    //        
    //
    //    }
    render() {
        return (
            <Col lg={12}>
                <Header />


                <Card bg="custom-info" >
                    <Card.Header>
                        <h4 className="text-center font-weight-bold mt-4">  সদস্য নিবন্ধন  </h4>
                        {this.state.CustomerNoShow && <h4 className="text-center  mt-4"> সদস্য/ সদস্যার নম্বর  </h4>}
                        {this.state.registrationShow && <h4 className="text-center  mt-4">  সদস্য/ সদস্যার তথ্য </h4>}




                        {this.state.collectionTypeShow && <h4 className="text-center mt-4"> হিসাব যুক্ত করুন </h4>}

                        {this.state.CheckShow && (<h4 className="text-center mt-4 row"><LocalizedMessage className="col-lg-12 col-md-12 col-12" messageKey="collection.overview" />


                        </h4>)}





                    </Card.Header>
                    <Card.Body>
                        <div className="steps-form mt-1 mb-4">
                            <div className="steps-row setup-panel">
                                <div className="steps-step">
                                    <button type="button" className={this.state.CustomerNoShow ? ' btn btn-circle waves-effect waves-light btn-indigo' : ' btn btn-circle waves-effect waves-light   btn-info'}> 1</button>

                                </div>
                                <div className="steps-step">
                                    <button type="button" className={(this.state.collectionTypeShow || this.state.registrationShow) ? ' btn btn-circle waves-effect waves-light btn-indigo' : ' btn btn-circle waves-effect waves-light  btn-info'} >2</button>

                                </div>
                                <div className="steps-step">
                                    <button type="button" className={this.state.CheckShow ? 'btn btn-circle waves-effect waves-light  btn-indigo' : ' btn btn-circle waves-effect waves-light  btn-info'} >3</button>



                                </div>
                            </div>
                        </div>

                        {this.state.CustomerNoShow &&
                            <Container>
                                <Row>
                                    <h4 className="text-center"><LocalizedMessage messageKey="collection.customerNo" /></h4>
                                    <Col lg={3}>

                                    </Col>
                                    <Col lg={6} className="mt-2 mb-5">
                                        <div className={`form-group md-form`}>
                                            <label data-error="wrong" data-success="right" className={this.state.isOpen ? 'active' : ''}>{this.props.label}</label>
                                            <input type="number"
                                                min="1"
                                                onChange={this.handleChangeNo}
                                                name="member_no"
                                                value={this.state.member_no}
                                                autoFocus={true}
                                                className="form-control"
                                            />

                                        </div>

                                    </Col>
                                    <Col lg={3}>

                                    </Col>


                                </Row>
                            </Container>
                        }
                        {this.state.registrationShow &&
                            <Container>
                                <Col lg={12}>
                                    <Form ref={this.registrationForm} noValidate>
                                        <Row>
                                            <Col lg="6">
                                                <SaInput
                                                    handleChange={this.handleChange}
                                                    autoFocus={false}
                                                    readOnly={false}
                                                    type="number"
                                                    value={this.state.member_no}
                                                    setState={s => this.setState(s)}
                                                    name="member_no"
                                                    disabled="1"
                                                    label={< LocalizedMessage messageKey="customer.member_no" />} required={true} />

                                            </Col>
                                            <Col lg="6">
                                                <SaInput
                                                    handleChange={this.handleChange}
                                                    autoFocus={true}
                                                    readOnly={false}
                                                    type="number"
                                                    value={this.state.national_id}
                                                    setState={s => this.setState(s)}
                                                    name="national_id"
                                                    label={'জাতীয় পরিচয় পত্র নম্বর'} required={true} />

                                            </Col>

                                            <Col lg="6">
                                                <SaInput
                                                    handleChange={this.handleChange}
                                                    autoFocus={false}
                                                    readOnly={false}
                                                    type="text"
                                                    value={this.state.phone_number}
                                                    setState={s => this.setState(s)}
                                                    name="phone_number"
                                                    required={false}
                                                    label={'ফোন নম্বর'} />
                                            </Col>
                                            <Col lg="6">
                                                {/* <SaInput
                                                    handleChange={this.handleChange}
                                                    autoFocus={false}
                                                    readOnly={false}
                                                    type="date"
                                                    value={this.state.date_of_bath}
                                                    setState={s => this.setState(s)}
                                                    name="date_of_bath"
                                                    required={false}
                                                    label={'জন্ম তারিখ '} /> */}

                                                <div className={`form-group md-form`}>
                                                    <label data-error="wrong" data-success="right" className={'active'}>জন্ম তারিখ</label>
                                                    <input
                                                        required={false}
                                                        onMouseLeave={this.handleChangeDate}
                                                        type='date'
                                                        onChange={this.handleChangeDate}
                                                        name="date_of_bath"
                                                        defaultValue={this.state.date_of_bath}
                                                        className="form-control"
                                                    />

                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <SaInput
                                                    handleChange={this.handleChange}
                                                    autoFocus={false}
                                                    type="email" value={this.state.email}
                                                    setState={s => this.setState(s)} name="email" label={'ইমেইল'} />
                                            </Col>
                                            <Col lg="6">
                                                <SaInput handleChange={this.handleChange}
                                                    autoFocus={false}
                                                    readOnly={false}
                                                    type="text"
                                                    value={this.state.name}
                                                    setState={s => this.setState(s)}
                                                    name="name"
                                                    label={'পূর্ণাঙ্গ নাম'}
                                                    required={true}
                                                />
                                            </Col>
                                            <Col lg="6">

                                                <SaInput handleChange={this.handleChange}
                                                    autoFocus={false}
                                                    readOnly={false}
                                                    type="text"
                                                    value={this.state.gardian}
                                                    setState={s => this.setState(s)}
                                                    name="gardian"
                                                    label={'পিতা/স্বামীর নাম'}
                                                    required={true}
                                                />

                                            </Col>
                                            <Col lg="6">

                                                <SaInput handleChange={this.handleChange}
                                                    autoFocus={false}
                                                    readOnly={false}
                                                    type="text"
                                                    value={this.state.mother_name}
                                                    setState={s => this.setState(s)}
                                                    name="mother_name"
                                                    label={'মাতার নাম'}
                                                    required={true}
                                                />

                                            </Col>
                                            <Col lg="6">

                                                <SaInput handleChange={this.handleChange}
                                                    autoFocus={false}
                                                    readOnly={false}
                                                    type="text"
                                                    value={this.state.current_address}
                                                    setState={s => this.setState(s)}
                                                    name="current_address"
                                                    label={'বর্তমান ঠিকানা '}
                                                    required={false}
                                                />

                                            </Col>








                                            {/* <Col lg="6">
                                                <InputGroup className="md-form">
                                                    <FormControl
                                                        placeholder="নমিনি"
                                                        aria-label="নমিনি"
                                                        aria-describedby="নমিনি"
                                                        required={true}
                                                        readOnly={true}
                                                    />
                                                    <InputGroup.Append>
                                                        <Button variant="outline-secondary" onClick={() => { this.selectnomin.openModal(null) }}> +</Button>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </Col> */}
                                            <Col lg="6">

                                                <Form.Group controlId="select_profession">
                                                    <Form.Label>পেশা </Form.Label>
                                                    <Form.Control name="profession" defaultValue={this.state.profession && this.state.profession} onChange={this.handleChangeSelect} as="select" className="sa-custome-select custom-select " required={true}>
                                                        <option></option>
                                                        <option value="1">কৃষি</option>
                                                        <option value="2">গৃহিনী</option>
                                                        <option value="3">চাকুরী</option>
                                                        <option value="4">ব্যবসা </option>
                                                        <option value="5">শিক্ষাকতা</option>
                                                        <option value="6">ডাক্তারী</option>
                                                        <option value="7">ম্যাকানিক</option>

                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>




                                            <Col lg="6">

                                                <Form.Group controlId="select_village">
                                                    <Form.Label>গ্রাম </Form.Label>
                                                    <Form.Control name="village_id" defaultValue={this.state.village_id && this.state.village_id} onChange={this.handleChangeSelect} as="select" className="sa-custome-select custom-select " required={true}>
                                                        <option></option>
                                                        {this.renderVillage()}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>

                                            <Col lg="6">

                                                <Form.Group controlId="select_post">
                                                    <Form.Label>পোস্ট  </Form.Label>
                                                    <Form.Control defaultValue={this.state.post_id && this.state.post_id} onChange={this.handleChangeSelect} name="post_id" as="select" className="sa-custome-select custom-select " required={true}>
                                                        <option> </option>
                                                        {this.renderPost()}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col lg="6">

                                                <Form.Group controlId="select_thana">
                                                    <Form.Label>থানা  </Form.Label>
                                                    <Form.Control defaultValue={this.state.thana_id && this.state.thana_id} onChange={this.handleChangeSelect} name="thana_id" as="select" className="sa-custome-select custom-select " required={true}>
                                                        <option> </option>
                                                        {this.renderThana()}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col lg="6">

                                                <Form.Group controlId="select_zilla">
                                                    <Form.Label>জিলা  </Form.Label>
                                                    <Form.Control defaultValue={this.state.zila_id && this.state.zila_id} onChange={this.handleChangeSelect} name="zila_id" as="select" className="sa-custome-select custom-select " required={true}>
                                                        <option> </option>
                                                        {this.renderZila()}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col lg="6">

                                                <Form.Group controlId="select_nationality">
                                                    <Form.Label>জাতীয়তা   </Form.Label>
                                                    <Form.Control name="nationality_id" defaultValue={this.state.nationality_id && this.state.nationality_id} onChange={this.handleChangeSelect} as="select" className="sa-custome-select custom-select " required={true}>
                                                        <option></option>
                                                        <option value="1">বাংলাদেশী</option>

                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col lg="6">

                                                <Form.Group controlId="select_religious">
                                                    <Form.Label>ধর্ম  </Form.Label>
                                                    <Form.Control name="religious_id" defaultValue={this.state.religious_id && this.state.religious_id} onChange={this.handleChangeSelect} as="select" className="sa-custome-select custom-select " required={true}>
                                                        <option></option>
                                                        <option value="1">সনাতন</option>
                                                        <option value="2">ইসলাম</option>

                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>










                                        </Row>
                                    </Form>


                                </Col>
                            </Container>
                        }
                        {this.state.collectionTypeShow &&
                            <Col lg={12}>


                                <Row>
                                    <Form className="col-lg-12" ref={this.AccountsForm} noValidate>
                                        <Row>



                                            {this.renderAccounts()}



                                            <Col xl={3} lg={4}>

                                                <Accordion defaultActiveKey="1">
                                                    <Card>
                                                        <Card.Header>
                                                            <Accordion.Toggle className="btn-block" as={Button} variant="info" eventKey="1">
                                                                আরো একাউন্ট
                                                    </Accordion.Toggle>
                                                        </Card.Header>
                                                        <Accordion.Collapse eventKey="1">
                                                            <Card.Body className="pt-0">


                                                                <Form.Control name="slect_product" onChange={this.handleChangeSelectProduct} as="select" className="sa-custome-select custom-select ">
                                                                    <option value="0">সিলেক্ট করুন</option>
                                                                    {this.renderProducts()}
                                                                </Form.Control>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>

                                                </Accordion>
                                            </Col>
                                        </Row>
                                    </Form>

                                </Row>
                            </Col>
                        }
                        {this.state.CheckShow &&
                            <Col lg={12}>
                                <Row>
                                    <Col lg={12}>
                                        <h4 className="text-center">{this.state.date}</h4>
                                    </Col>


                                    <Col xl={2} lg={4} xs={12} md={3}>
                                        <Row>
                                            <Col  >

                                            </Col>
                                            <Col lg={12} md={12} className="pt-5">
                                                <Card className="custom-card mt-5">
                                                    <Card.Img variant="top" className="rounded-circle cu-card-img-top" src={this.state.profileFile} />

                                                    <Card.Body className="text-center">
                                                        <input ref={this.ChengeProfileInput} type="file" style={{ display: 'none' }} onChange={this.handleProfileChange} />
                                                        <Button onClick={this.ChengeProfilePhotofunc} variant="outline-secondary cu-change-hover" size="sm" >Chenge</Button>

                                                        <Card.Title><Link to="customer/profile"><small className="text-muted">{this.state.customer.name ? this.state.customer.name : this.state.name}</small> </Link> </Card.Title>
                                                        <Card.Text>
                                                            {this.state.customer.address ? this.state.customer.address : this.state.customerAddress}
                                                        </Card.Text>
                                                    </Card.Body>
                                                    <Card.Footer className="text-center">
                                                        <Image className="img-fluid" src={this.state.signFile} rounded />


                                                        <input ref={this.ChengeSignInput} type="file" style={{ display: 'none' }} onChange={this.handleSignChange} />

                                                        <Button onClick={this.ChengeSignPhotofunc} variant="outline-secondary" size="sm" >Chenge</Button>
                                                    </Card.Footer>
                                                </Card>

                                            </Col>
                                            <Col >

                                            </Col>
                                        </Row>




                                    </Col>
                                    <Col xl={10} xs={12} lg={8} md={9} className="pt-5">
                                        <Row>


                                            {this.renderSumarizedAccounts()}



                                        </Row>



                                    </Col>
                                </Row>
                            </Col>
                        }



                    </Card.Body>
                    <Card.Footer>
                        <Row>
                            <Col lg="6" md={6} xs={6}>


                                {this.state.collectionTypeShow &&
                                    <button onClick={this.cancelCollection} className="btn  btn-rounded prevBtn  waves-effect waves-light" type="button"> <LocalizedMessage messageKey="button.cancel" /> </button>
                                }
                                {this.state.CheckShow &&
                                    <button onClick={this.cancelCheck} className="btn  btn-rounded prevBtn  waves-effect waves-light" type="button"> <LocalizedMessage messageKey="button.cancel" /> </button>
                                }
                                {this.state.registrationShow &&
                                    <button onClick={this.cancelRegistration} className="btn  btn-rounded prevBtn  waves-effect waves-light" type="button"> <LocalizedMessage messageKey="button.cancel" /> </button>
                                }





                            </Col>
                            <Col lg="6" md={6} xs={6}>
                                {this.state.CustomerNoShow &&
                                    <SaButtonNext messageKey="button.next" clickFunc={this.checkCustomerNo} disabled={this.state.disabled} />
                                }
                                {this.state.registrationShow &&

                                    <SaButtonNext messageKey="button.next" clickFunc={this.registrationFunc} disabled={this.state.disabled} />


                                }
                                {this.state.collectionTypeShow &&

                                    <SaButtonNext messageKey="button.next" clickFunc={this.CheckData} disabled={this.state.disabled} />


                                }

                                {this.state.CheckShow &&
                                    <SaButtonNext messageKey="button.save" clickFunc={this.saveData} disabled={this.state.disabled} />


                                }


                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>


                <ConfirmCustomerModal onRef={ref => this.selectcustomer = ref} />
                {/* <NominiModal onRef={ref => this.selectnomin = ref} /> */}



            </Col>


        )
    }

}
const mapStateToProps = state => ({
    customer: state.customer,
    errors: state.errors
});


export default withTranslation()(connect(mapStateToProps, { intRegistration, deleteUserById, saveCustomer, checkCustomer, EnglishToBanglsNumber })(CustomerAdd));