/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import React, { Component }
    from 'react';
import { Link }
    from 'react-router-dom';

import { connect }
    from 'react-redux';
import { withTranslation }
    from 'react-i18next';

import Header from '../../common/Header';
import { CheckUserById, SaveCollection } from '../../actions/collectionActions';

import LocalizedMessage from '../../common/LocalizedMessage';
import { Form, Row, Col, Container, Breadcrumb, Card, Button, Input, InputGroup, FormControl }
    from 'react-bootstrap';

import SaInput from '../../common/SaInput';

import SaDateInput from '../../common/SaDateInput';

import SaButtonInput from '../../common/SaButtonInput';
import ConfirmCustomerModal from './ConfirmCustomerModal';

const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
var convertToBangla = require('number-translate-bangla');



var EnglishToBanglsNumber = require('../../common/EnglishToBanglsNumber');

var BanglaToEnglishNumber = require('../../common/BanglaToEnglishNumber');



class Collection extends Component {

    constructor() {
        super();

        var date = (new Date()).toLocaleDateString('bn-BD');

        this.state = {
            next: '',
            customer: [],
            data: [],
            user_id: '',
            memo_no: '',
            sheet_no: '',
            date: date,
            share: '',
            saving: '',
            CustomerNoShow: true,
            collectionTypeShow: false,
            anotherCollection: false,
            CheckShow: false,
            inputFiled: [],
            addbuttonCLick: false,
            checkCustomerNobuttonClick: false
        }

    }

    checkCustomerNo = () => {

        if (this.state.user_id !== '') {
            this.props.CheckUserById(this.state.user_id);

            this.setState({ checkCustomerNobuttonClick: true });



        } else {
            this.setState({ CustomerNoShow: false, anotherCollection: true });

        }

        this.setState({ loading: true });
    }

    CheckData = () => {

        this.setState({ collectionTypeShow: false, CheckShow: true });
        this.setState({ loading: true });
    }

    covertEnBdDate = (date) => {
     //   console.log(date);
       var d = date.split("/");

      // console.log(d);

    return BanglaToEnglishNumber(d[2])+'-'+BanglaToEnglishNumber(d[1])+'-'+BanglaToEnglishNumber(d[0]); 


    }

    saveData = () => {
        let data = {
            user_id: this.state.user_id,
            memo_no: this.state.memo_no,
            sheet_no: this.state.sheet_no,
            inputFiled: this.state.inputFiled,
            date: this.covertEnBdDate(this.state.date)
        }


       this.props.SaveCollection(data);
       this.setState({ loading: true, addbuttonCLick: true });
     //console.log(data);
    }

    cancelCollection = () => {
        this.setState({ collectionTypeShow: false, CustomerNoShow: true });
    }

    cancelanotherCollection = () => {
        this.setState({ CustomerNoShow: true, CustomerNoShow: true });
    }

    cancelCheck = () => {
        this.setState({ collectionTypeShow: true, CheckShow: false });
    }

    componentWillReceiveProps(nextProps) {
        var { check_user_by_id, save_collection, loading } = nextProps.collection;

        console.log(nextProps);



        if (loading === false && check_user_by_id && check_user_by_id !== null && this.state.addbuttonCLick === false) {
            this.setState({ customer: check_user_by_id.customer });
            this.setState({ data: check_user_by_id.data });
            this.setState({ products: check_user_by_id.products });
            if (this.state.checkCustomerNobuttonClick) {
                this.setState({ CustomerNoShow: false, collectionTypeShow: true });
            }
            this.setState({ checkCustomerNobuttonClick: false });


        } else if (loading === false && save_collection && save_collection !== null && this.state.addbuttonCLick) {

            this.props.history.push('/collection');
        } else if (nextProps.errors.errors && nextProps.errors.errors !== null && nextProps.errors.errors.error === 'invalid_grant') {
            this.selectcustomer.openModal();
            //  console.log('invalid grant');
        }



        // console.log(customer.get_user_by_id.data);



    }
    renderAccounts = () => {
        var data = this.state.data;
        let products = this.state.products;
        if (data.length > 0) {
            return (data.map((singleaccount, index) => (
                <Col lg={3} key={index}>
                    <SaButtonInput isOpen="0" inputFiled={this.state.inputFiled} readOnly={false} value={this.state[singleaccount.value]} type="number" product_id={singleaccount.product_id} accountNo={singleaccount.account_number} setState={s => this.setState(s)} name={index} label={products[singleaccount.product_id - 1].title} />

                </Col>
            )
            )
            );

        }
    }
    renderSumarizedAccounts = () => {

        var inputedFiled = this.state.inputFiled;


        let products = this.state.products;


        if (inputedFiled.length > 0) {
            return (inputedFiled.map((singleaccount, index) => (
                //   inputedFiled[singleaccount.product[0].id] > 0 &&
                <Col md="6" key={index}>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <Button variant="info">{products[singleaccount.product_id - 1].title}</Button>
                        </InputGroup.Prepend>
                        <FormControl disabled={true} defaultValue={singleaccount.value} aria-describedby="basic-addon1" />
                    </InputGroup>
                </Col>

            )
            )
            );

        }

    }

    sum = (obj) => {
        var sum = 0;
        for (var el in obj) {
            if (obj.hasOwnProperty(el)) {
                sum += parseFloat(obj[el].value);
            }
        }
        return sum;
    }
    handleChange = (name,value) => {




    }

    handelCancelcustomer = () =>{
        this.props.history.push('/lezar/');
    }

    handelAddcustomer = () =>{
        this.props.history.push('/customer/add/');
    }



    //    totalAmountRender = () => {
    //        let totalAmount =0;
    //
    //        var inputedFiled = this.state.inputFiled;
    //        
    //         
    //                   
    //            console.log(inputedFiled);
    //       
    //        
    //        
    //
    //    }
    render() {
        return (
            <Col lg={12}>
                <Header />
                <Container>

                    <Card bg="custom-info" >
                        <Card.Header>

                            {this.state.CustomerNoShow && <h4 className="text-center font-weight-bold mt-4">  <LocalizedMessage messageKey="collection.title" /> </h4>}

                            {this.state.anotherCollection && <h4 className="text-center font-weight-bold mt-4">        <h4 className="text-center">অন্যান্য হিসাব যুক্ত করুন </h4></h4>}






                            {this.state.collectionTypeShow && <h4 className="text-center mt-4"><LocalizedMessage messageKey="collection.types" /></h4>}

                            {this.state.CheckShow && (<h4 className="text-center mt-4 row"><LocalizedMessage className="col-lg-12 col-md-12 col-12" messageKey="collection.overview" />

                                <small className="col-lg-12 col-md-12 col-12 pt-3 text-info"> সর্বমোট  : {convertToBangla(this.sum(this.state.inputFiled))} টাকা  </small>
                            </h4>)}





                        </Card.Header>
                        <Card.Body>
                            <div className="steps-form mt-1 mb-4">
                                <div className="steps-row setup-panel">
                                    <div className="steps-step">
                                        <button type="button" className={this.state.CustomerNoShow ? ' btn btn-circle waves-effect waves-light btn-indigo' : ' btn btn-circle waves-effect waves-light   btn-info'}> 1</button>

                                    </div>
                                    <div className="steps-step">
                                        <button type="button" className={this.state.collectionTypeShow ? ' btn btn-circle waves-effect waves-light btn-indigo' : ' btn btn-circle waves-effect waves-light  btn-info'} >2</button>

                                    </div>
                                    <div className="steps-step">
                                        <button type="button" className={this.state.CheckShow ? 'btn btn-circle waves-effect waves-light  btn-indigo' : ' btn btn-circle waves-effect waves-light  btn-info'} >3</button>



                                    </div>
                                </div>
                            </div>

                            {this.state.CustomerNoShow &&
                                <Row>
                                    <h4 className="text-center"><LocalizedMessage messageKey="collection.customerNo" /></h4>
                                    <Col lg={3}>

                                    </Col>
                                    <Col lg={6} className="mt-2 mb-5">
                                        <SaInput handleChange={this.handleChange} autoFocus={true} readOnly={false} type="number" value={this.state.user_id} setState={s => this.setState(s)} name="user_id" label={< LocalizedMessage messageKey="customer.customerNo" />} />
                                        <small className="text-info">অন্য কোনো জমার ক্ষেত্রে <b>পরবর্তী</b> বাটন এ ক্লিক করুন </small>

                                    </Col>
                                    <Col lg={3}>

                                    </Col>


                                </Row>
                            }

                            {this.state.anotherCollection &&
                                <Row>

                                    <Col lg={3}>

                                    </Col>
                                    <Col lg={6} className="mt-2 mb-5">

                                        <SaInput handleChange={this.handleChange} autoFocus={true} readOnly={false} type="number" value={this.state.customerNo} setState={s => this.setState(s)} name="bank" label={'   ব্যাঙ্ক জমা '} />


                                        <SaInput handleChange={this.handleChange} autoFocus={false} readOnly={false} type="number" value={this.state.customerNo} setState={s => this.setState(s)} name="pasbooksell" label={' পাশ বই বিক্রি '} />

                                        <SaInput handleChange={this.handleChange} autoFocus={false} readOnly={false} type="number" value={this.state.customerNo} setState={s => this.setState(s)} name="formsell" label={' ফর্ম বিক্রি  '} />
                                    </Col>
                                    <Col lg={3}>

                                    </Col>


                                </Row>
                            }



                            {this.state.collectionTypeShow &&
                                <Col lg={12}>


                                    <Row>



                                        <Col lg={3}>
                                            <SaDateInput handleChange={this.handleChange} readOnly={false} value={this.state.date} type="text" setState={s => this.setState(s)} name="date" label={< LocalizedMessage messageKey="collection.date" />} />


                                        </Col>
                                        <Col lg={3} >
                                            <SaInput handleChange={this.handleChange} readOnly={true} value={this.state.user_id} type="number" setState={s => this.setState(s)} name="customerNo" label={< LocalizedMessage messageKey="customer.customerNo" />} />



                                        </Col>
                                        <Col lg={3}>
                                            <SaInput handleChange={this.handleChange} autoFocus={true} readOnly={false} value={this.state.memo_no} type="number" setState={s => this.setState(s)} name="memo_no" label={< LocalizedMessage messageKey="collection.memoNo" />} />

                                        </Col>

                                        <Col lg={3}>
                                            <SaInput handleChange={this.handleChange} autoFocus={false} readOnly={false} value={this.state.sheet_no} type="number" setState={s => this.setState(s)} name="sheet_no" label={< LocalizedMessage messageKey="collection.sheetNo" />} />

                                        </Col>
                                        {this.renderAccounts()}
                                        <Col lg={3}>

                                            <Card>
                                                <Card.Header>
                                                    <button onClick={() => { this.props.history.push('/customer/add/' + this.state.user_id); }} className="btn btn-outline-info" >আরো হিসাব যুক্ত করুন </button>
                                                </Card.Header>

                                            </Card>

                                        </Col>

                                    </Row>
                                </Col>
                            }
                            {this.state.CheckShow &&
                                <Col lg={12}>
                                    <Row>
                                        <Col lg={12}>
                                            <h4 className="text-center">{this.state.date}</h4>
                                        </Col>


                                        <Col lg={4} md={6}>
                                            <Row>
                                                <Col  >

                                                </Col>
                                                <Col lg={12} md={12} className="pt-5">
                                                    <Card className="custom-card mt-5">
                                                        <Card.Img variant="top" className="rounded-circle " src="assets/img/humna.jpg" />
                                                        <Card.Body className="text-center">
                                                            <Card.Title>{this.state.customer.name ? this.state.customer.name : 'সদস্যের নাম'} </Card.Title>
                                                            <Card.Text>

                                                                {this.state.customer.current_address ? this.state.customer.current_address : ' সদস্যের ঠিকানা'}
                                                            </Card.Text>
                                                        </Card.Body>
                                                        <Card.Footer className="text-center">
                                                            <Link to="customer/profile"><small className="text-muted"> <LocalizedMessage messageKey="customer.ptofile" /></small> </Link>
                                                        </Card.Footer>
                                                    </Card>

                                                </Col>
                                                <Col >

                                                </Col>
                                            </Row>




                                        </Col>
                                        <Col lg={8} md={6} className="pt-5">
                                            <Row>
                                                <Col md="6" sm={12} sm={12}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <Button variant="outline-success">< LocalizedMessage messageKey="collection.memoNo" /></Button>
                                                        </InputGroup.Prepend>
                                                        <FormControl disabled={true} defaultValue={this.state.memo_no} aria-describedby="basic-addon1" />
                                                    </InputGroup>
                                                </Col>
                                                <Col md="6" sm={12} sm={12}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <Button variant="outline-success">< LocalizedMessage messageKey="collection.sheetNo" /></Button>
                                                        </InputGroup.Prepend>
                                                        <FormControl disabled={true} defaultValue={this.state.sheet_no} aria-describedby="basic-addon1" />
                                                    </InputGroup>
                                                </Col>

                                                {this.renderSumarizedAccounts()}



                                            </Row>



                                        </Col>
                                    </Row>
                                </Col>
                            }



                        </Card.Body>
                        <Card.Footer>
                            <Row>
                                <Col lg="6" md={6} xs={6}>


                                    {this.state.collectionTypeShow &&
                                        <button onClick={this.cancelCollection} className="btn  btn-rounded prevBtn  waves-effect waves-light" type="button"> <LocalizedMessage messageKey="button.cancel" /></button>
                                    }
                                    {this.state.CheckShow &&
                                        <button onClick={this.cancelCheck} className="btn  btn-rounded prevBtn  waves-effect waves-light" type="button"> <LocalizedMessage messageKey="button.cancel" /></button>
                                    }


                                    {this.state.anotherCollection &&
                                        <button onClick={this.cancelanotherCollection} className="btn  btn-rounded prevBtn  waves-effect waves-light" type="button"> <LocalizedMessage messageKey="button.cancel" /></button>
                                    }


                                </Col>
                                <Col lg="6" md={6} xs={6}>
                                    {this.state.CustomerNoShow &&
                                        <button onClick={this.checkCustomerNo} className="btn btn-indigo btn-rounded nextBtn float-right waves-effect waves-light" type="button"> <LocalizedMessage messageKey="button.next" /></button>
                                    }
                                    {this.state.collectionTypeShow &&
                                        <button onClick={this.CheckData} className="btn btn-indigo btn-rounded nextBtn float-right waves-effect waves-light" type="button"> <LocalizedMessage messageKey="button.next" /></button>
                                    }
                                    {this.state.CheckShow &&
                                        <button onClick={this.saveData} className="btn btn-indigo btn-rounded prevBtn  float-right waves-effect waves-light" type="button"> <LocalizedMessage messageKey="button.save" /></button>
                                    }

                                    {this.state.anotherCollection &&
                                        <button onClick={this.saveData} className="btn btn-indigo btn-rounded prevBtn  float-right waves-effect waves-light" type="button"> <LocalizedMessage messageKey="button.save" /></button>
                                    }




                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>

                    <ConfirmCustomerModal handelCancelcustomer={this.handelCancelcustomer} handelAddcustomer={this.handelAddcustomer} onRef={ref => this.selectcustomer = ref} />

                </Container>


            </Col>


        )
    }

}
const mapStateToProps = state => ({
    collection: state.collection,
    errors: state.errors
});


export default withTranslation()(connect(mapStateToProps, { CheckUserById, SaveCollection, EnglishToBanglsNumber })(Collection));