import React, { Component } from 'react';
import { Link }
    from 'react-router-dom';


import { Table, Container, Card, Button, Col, Row } from 'react-bootstrap';
var EnglishToBanglsNumber = require('../../common/EnglishToBanglsNumber');
var EnglishToBanglaDate = require('../../common/EnglishToBanglaDate');
var EnglishToBanglaNumber = require('../../common/EnglishToBanglaNumber');
export default class EducationLezarLayout extends Component {





    renderRows = () => {
        let html = [];

        if (this.props.data && Object.keys(this.props.data).length > 0) {

            let data = this.props.data;



            Object.keys(data).forEach(function (year) {
                html.push(<tr key={year} className="table-info">
                  <td colSpan="6" ><b>{EnglishToBanglaNumber(year)} সন </b> </td>

                </tr>);
                    Object.keys(data[year]).forEach(function (month) {
                        Object.keys(data[year][month]).forEach(function (memo_no) {
                            html.push( <tr key={year+month+memo_no}>
                                <td>
                                    {EnglishToBanglaDate(data[year][month][memo_no][0].date)}
                                </td>
                                <td>
                                {EnglishToBanglaNumber(data[year][month][memo_no][0].memo_no)}
                                </td>
                                <td>
                                {data[year][month][memo_no][0].type===1 && EnglishToBanglsNumber(data[year][month][memo_no][0].amount)}
                                </td>
                                <td>
                                {data[year][month][memo_no][0].type===0 && EnglishToBanglsNumber(data[year][month][memo_no][0].amount)}
                                </td>
                                <td>
                                {EnglishToBanglsNumber(data[year][month][memo_no][0].amount)}
                                </td>
                                <td>
                                    4
                                </td>
                            </tr>);
                        });
                        
                    });




            });






        }
        return html;
    }

    render() {
        return (
            <Col lg="12 lazar_view">
                <Container className="mb-3">

                    <Row>
                        <Col className="12">
                            <h3 className="text-center text-info">শিক্ষা সঞ্চয়ের  খতিয়ান </h3>
                        </Col>

                    </Row>


                    <Row>
                        <Col lg={2} md={6}>
                            <Card.Img variant="top" className="rounded " src="/assets/img/humna.jpg" />
                        </Col>
                        <Col lg="10">
                            <p className="mb-0">হিসাব নম্বর  : <b>{this.props.child && EnglishToBanglaNumber(('000000' + this.props.child.account_number).slice(-5))}</b></p>
                            <p className="mb-0">নাম : <b> {this.props.child && this.props.child.sub_name}</b></p>
                           <p className="mb-0">অভিভাবকের নাম  : <b> {this.props.gardian.name && this.props.gardian.name}</b></p>
                            <p className="mb-0">অভিভাবকের সদস্য নম্বর : <b>{this.props.gardian.member_no && EnglishToBanglaNumber(('000000' + this.props.gardian.member_no).slice(-5))}</b></p>
                            <p className="mb-0">ঠিকানা  : <b> {this.props.gardian.village.village && this.props.gardian.village.village + ' , ' + this.props.gardian.thana.thana_name}</b></p>
                        </Col>
                    </Row>
                </Container>

                <Row>
                    <Container>
                        <Table responsive striped bordered hover >
                            <thead>

                                <tr>
                                    <th >তারিখ </th>
                                    <th>রশিদের পৃষ্টা নং </th>
                                    <th>জমা </th>
                                    <th>উত্তোলন  </th>
                                    <th>স্থিতি  </th>
                                    <th>প্রোডাক্ট </th>
                                </tr>
                            </thead>
                            <tbody>

                                {this.renderRows()}



                            </tbody>
                        </Table>
                    </Container>

                </Row>


            </Col>
        )

    }



}