import React, { Component } from 'react';
import Modal from 'react-modal';
import LocalizedMessage from '../../common/LocalizedMessage';

import { Button }
    from 'react-bootstrap';
import { connect }
    from 'react-redux';

import { Link } from 'react-router-dom';
const customStyles = {
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxWidth: '500px',
        padding: '0',
        border: '0',
        background: ''
    }
};

Modal.setAppElement('#root');
Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.5)';
Modal.defaultStyles.overlay.zIndex = '1050';

class ConfirmCustomerModal extends Component {



    state = {
        modalIsOpen: false,
        customerID: '',
        name: ''
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null)
    }
    componentWillReceiveProps(nextprops) {
     //   console.log('Confrim customer model');

    }

    openModal = () => {


        this.setState({ modalIsOpen: true });

    }


    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }





    render() {
        return (
            <div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div id="myModal">
                        <div className="modal-dialog modal-animations">
                            <div className="modal-content pl-4 pr-4 pb-0">
                                <div className="modal-header">
                                    <strong className="text-center">দুঃখিত ! কোনো সদস্য পাওয়া  যায়নি</strong>
                                    {/* <strong className="modal-title">Delete</strong> */}
                                    {/* <button type="button" className="close" onClick={this.closeModal}>×</button> */}
                                </div>
                                <div className="modal-body">
                                    <p className="text-center">নতুন সদস্য নিবন্ধন করতে চান ?</p>

                                </div>
                                <div className="col-lg-12 col-md-12 pb-3">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <Button onClick={this.props.handelCancelcustomer} className=" btn btn-outline-danger " variant="outline-danger">না</Button>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                        <Button  onClick={this.props.handelAddcustomer}   className=" btn btn-info float-right">হ্যাঁ</Button>
                                        </div>

                                    </div>



                                </div>


                            </div>
                        </div>
                    </div>
                </Modal>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    customer: state.customer
})

export default connect(mapStateToProps)(ConfirmCustomerModal);