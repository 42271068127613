import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../../common/Header';
import {showToastNotification} from '../../common/showToastNotification';

import {connect} from 'react-redux';
import {getLocalizedMessages} from '../../actions/localizedMessageActions';
import {getUserSettings} from '../../actions/userActions';

import {messageKeys} from '../../common/messageKeys';
import LocalizedMessage from '../../common/LocalizedMessage';


import {Col, Container} from 'react-bootstrap';


class Dashboard extends Component {
    componentDidMount() { // window.history.pushState(null, null, '/')


        document.title = "Co oparative credit union management system | Home";
        this.props.getLocalizedMessages(messageKeys);

        this.props.getUserSettings();


    }

    componentWillReceiveProps(nextProps) {

        if (nextProps && nextProps.message && nextProps.message.key) {

            if (nextProps.message.key.dictionary && nextProps.message.key.dictionary) {
                localStorage.setItem('dictionary', JSON.stringify(nextProps.message.key.dictionary));
            }
        }


        const update = nextProps.location.state && nextProps.location.state.update;
        const save = nextProps.location.state && nextProps.location.state.save;

        if (update === true) {
            let message = localStorage.dictionary && JSON.parse(localStorage.getItem('dictionary'))['productConfiguration.notification.update'];
            showToastNotification(message);
        }

        if (save === true) {
            let message = localStorage.dictionary && JSON.parse(localStorage.getItem('dictionary'))['productConfiguration.notification.save'];
            showToastNotification(message);
        }
    }

    render() {
        return (
            <Col> {
                localStorage.dictionary && <Header/>
            }

                <Container fluid={true}>
                    <div id="show-notification"></div>
                    <div className="row mainContent">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12  col-12 equalHeight hover_effect ">
                            <Link to='/customer'
                                style={
                                    {color: '#fff'}
                            }>
                                <div className="col-sm-12  text-center bg-lightorange  py-5 ">

                                    <img style={
                                            {height: '100px'}
                                        }
                                        src="assets/img/user-md.svg"
                                        alt='Product Configurator Logo'
                                        className="img-fluid"/>
                                    <h5 className="menu-upper-text pt-4">
                                        <LocalizedMessage messageKey="menu.customer"/>
                                    </h5>
                                </div>
                            </Link>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12  col-12 equalHeight hover_effect ">
                            <Link to='/collection'
                                style={
                                    {color: '#fff'}
                            }>
                                <div className="col-sm-12  text-center bg-lightblue  py-5 ">

                                    <img style={
                                            {height: '100px'}
                                        }
                                        src="assets/img/money-bill.svg"
                                        alt='Product Configurator Logo'
                                        className="img-fluid"/>
                                    <h5 className="menu-upper-text pt-4">
                                        <LocalizedMessage messageKey="menu.credit"/>
                                    </h5>
                                </div>
                            </Link>
                        </div>


                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12  col-12 equalHeight  hover_effect">
                            <Link to='/expense'
                                style={
                                    {color: '#fff'}
                            }>
                                <div className="col-sm-12  text-center bg-lightdarkorange py-5">

                                    <img style={
                                            {height: '100px'}
                                        }
                                        src="assets/img/money-bill-slash.svg"
                                        alt='Product Configurator Logo'
                                        className="img-fluid"/>
                                    <h5 className="menu-upper-text pt-4">
                                        <LocalizedMessage messageKey="menu.debit"/>
                                    </h5>
                                </div>
                            </Link>
                        </div>


                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12  col-12 equalHeight hover_effect ">
                            <Link to='/lezar'
                                style={
                                    {color: '#fff'}
                            }>
                                <div className="col-sm-12  text-center bg-lightvariant  py-5 ">

                                    <img style={
                                            {height: '100px'}
                                        }
                                        src="assets/img/receipt-alt.svg"
                                        alt='Product Configurator Logo'
                                        className="img-fluid"/>
                                    <h5 className="menu-upper-text pt-4">
                                        <LocalizedMessage messageKey="menu.lezar"/>
                                    </h5>
                                </div>
                            </Link>
                        </div>


                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12  col-12 equalHeight  hover_effect disabled">
                            <Link to='/'
                                style={
                                    {color: '#fff'}
                            }>
                                <div className="col-sm-12  text-center bg-lightviolate py-5">

                                    <img style={
                                            {height: '100px'}
                                        }
                                        src="assets/img/receipt.svg"
                                        alt='Product Configurator Logo'
                                        className="img-fluid"/>
                                    <h5 className="menu-upper-text pt-4">
                                        <span>বিস্তারিত বিবরণ
                                        </span>
                                    </h5>
                                </div>
                            </Link>
                        </div>
                        

                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12  col-12 equalHeight hover_effect disabled">
                            <Link to='/'
                                style={
                                    {color: '#fff'}
                            }>
                                <div className="col-sm-12  text-center bg-lightvariant  py-5 ">

                                    <img style={
                                            {height: '100px'}
                                        }
                                        src="assets/img/database-alt.svg"
                                        alt='Product Configurator Logo'
                                        className="img-fluid"/>
                                    <h5 className="menu-upper-text pt-4">
                                       <span>কাশ খাতা</span>
                                    </h5>
                                </div>
                            </Link>
                        </div>


                       
                      

                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12  col-12 equalHeight  hover_effect disabled">
                            <Link to='/'
                                style={
                                    {color: '#fff'}
                            }>
                                <div className="col-sm-12  text-center bg-lightgreen py-5">

                                    <img style={
                                            {height: '100px'}
                                        }
                                        src="assets/img/comment-alt-chart-lines.svg"
                                        alt='Product Configurator Logo'
                                        className="img-fluid"/>
                                    <h5 className="menu-upper-text pt-4">
                                        <span>সাধারণ খতিয়ান
                                        </span>
                                    </h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12  col-12 equalHeight  hover_effect disabled">
                            <Link to='/'
                                style={
                                    {color: '#fff'}
                            }>
                                <div className="col-sm-12  text-center bg-lightviolate py-5">

                                    <img style={
                                            {height: '100px'}
                                        }
                                        src="assets/img/calculator.svg"
                                        alt='Product Configurator Logo'
                                        className="img-fluid"/>
                                    <h5 className="menu-upper-text pt-4">
                                        <span>প্রাপ্তি প্রদান হিসাব
                                        </span>
                                    </h5>
                                </div>
                            </Link>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12  col-12 equalHeight  hover_effect disabled">
                            <Link to='/'
                                style={
                                    {color: '#fff'}
                            }>
                                <div className="col-sm-12  text-center bg-green py-5">

                                    <img style={
                                            {height: '100px'}
                                        }
                                        src="assets/img/chart-pie-alt.svg"
                                        alt='Product Configurator Logo'
                                        className="img-fluid"/>
                                    <h5 className="menu-upper-text pt-4">
                                        <span>উদ্বৃত্ত পত্র
                                        </span>
                                    </h5>
                                </div>
                            </Link>
                        </div> 
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12  col-12 equalHeight  hover_effect disabled">
                            <Link to='/'
                                style={
                                    {color: '#fff'}
                            }>
                                <div className="col-sm-12  text-center bg-Foggy  py-5">

                                    <img style={
                                            {height: '100px'}
                                        }
                                        src="assets/img/chart-line.svg"
                                        alt='Product Configurator Logo'
                                        className="img-fluid"/>
                                    <h5 className="menu-upper-text pt-4">
                                        <span>লাভ ক্ষতি হিসাব
                                        </span>
                                    </h5>
                                </div>
                            </Link>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12  col-12 equalHeight  hover_effect disabled">
                            <Link to='/'
                                style={
                                    {color: '#fff'}
                            }>
                                <div className="col-sm-12  text-center bg-Arches py-5">

                                    <img style={
                                            {height: '100px'}
                                        }
                                        src="assets/img/assistive-listening-systems.svg"
                                        alt='Product Configurator Logo'
                                        className="img-fluid"/>
                                    <h5 className="menu-upper-text pt-4">
                                        <span>লাভ ক্ষতি বন্টন হিসাব</span>
                                    </h5>
                                </div>
                            </Link>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12  col-12 equalHeight  hover_effect disabled">
                            <Link to='#'
                                style={
                                    {color: '#fff'}
                            }>
                                <div className="col-sm-12  text-center bg-Rausch py-5">

                                    <img style={
                                            {height: '100px'}
                                        }
                                        src="assets/img/arrow-right.svg"
                                        alt='Product Configurator Logo'
                                        className="img-fluid"/>
                                    <h5 className="menu-upper-text pt-4">
                                        <span>অন্যান্য</span>
                                    </h5>
                                </div>
                            </Link>
                        </div>


                    </div>
                </Container>
            </Col>
        )
    }
}

const mapStateToProps = state => ({message: state.message})


export default connect(mapStateToProps, {getLocalizedMessages, getUserSettings})(Dashboard);
