/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import React, { Component }
  from 'react';
import { Link }
  from 'react-router-dom';

import { connect }
  from 'react-redux';
import { withTranslation }
  from 'react-i18next';

import Header from '../../common/Header';
import { checkCustomer } from '../../actions/collectionActions';

import { getAllCustomers, CustomersSearch } from '../../actions/customerActions';

import LocalizedMessage from '../../common/LocalizedMessage';
import { Form, Row, Col, Table, Container, Breadcrumb, Card, Button, Input, InputGroup, FormControl }
  from 'react-bootstrap';

import SaInput from '../../common/SaInput';

import SaDateInput from '../../common/SaDateInput';

import SaButtonInput from '../../common/SaButtonInput';
import Spinner from '../../common/Spinner';
import ChoiceModal from './ChoiceModal';
var EnglishToBanglsNumber = require('../../common/EnglishToBanglsNumber');




var EnglishToBanglaNumber = require('../../common/EnglishToBanglaNumber');

class Customer extends Component {

  constructor() {
    super();
    this.state = {
      next: '',
      all_customers: [],
      villages: [],
      loading: true
    }
  }

  componentDidMount() {
    this.props.getAllCustomers();
  }
  componentWillReceiveProps(nextProps) {



    const { all_customers, customer_search, loading } = nextProps.all_customers;

    if (all_customers !== null && loading === false) {

      this.setState({ all_customers: all_customers.customer });

      this.setState({ villages: all_customers.village });
      this.setState({ loading: false })

    }

    if (customer_search !== null && loading === false) {
      //console.log('customer_search');
      // console.log(customer_search);
      this.setState({ all_customers: customer_search });

      this.setState({ loading: false })

    }




  }
  ShowCustomers() {
    //  console.log(this.state.all_customers.customer);
    if (this.state.all_customers.length > 0) {


      return this.state.all_customers && this.state.all_customers.map((customer, index) => (
        <tr key={index} onClick={() => { this.choicemodel.openModal(customer) }}>
          <td>{EnglishToBanglaNumber(('000000' + customer.member_no).slice(-5))}</td>
          {/* <td>{customer.profile_pic}</td> */}
          <td>{customer.name}</td>
          <td>{customer.village && customer.village.village + ', পোস্ট : ' + customer.post.post_name + ' , ' + customer.thana.thana_name + ' , ' + customer.zila.zila_name}</td>
        </tr>
      ))
    }

  }
  ShowVillages() {
    //  console.log(this.state.all_customers.customer);
    if (this.state.villages.length > 0) {
      return this.state.villages && this.state.villages.map((village, index) => (
        village.village !== '' &&
        <option key={index} value={village.id}>{village.village}</option>
      ))
    }

  }
  handleVillageChange = (event) => {

    this.setState({ loading: true });
    let data = {
      'item': 'village_id',
      'value': event.target.value
    }


    this.props.CustomersSearch(data);
  }
  handleSearch = (event) => {
    this.setState({ loading: true });
    let data = {
      'item': 'name',
      'value': this.state.name
    }


    this.props.CustomersSearch(data);

  }

  printDiv = (id) => {
    var divToPrint = document.getElementById(id);
    var htmlToPrint = '' +
      '<style type="text/css">' +
      'table th, table td {' +
      'border:1px solid #000;' +
      'padding;0.5em;' +
      '}' +
      '</style>';
    htmlToPrint += divToPrint.outerHTML;
    let newWin = window.open("");
    //    newWin.document.write("<h3 align='center'>Print Page</h3>");
    newWin.document.write(htmlToPrint);
    newWin.print();
    newWin.close();
  }

  print = (e) => {
    e.preventDefault();
    this.printDiv("printContent");
  }
  handleChange = e => {
    e.preventDefault();
    var name = e.target.name;

    this.setState({ name: e.target.value });
  }

  handleRedirect = (member_no) => {

    // console.log('handel redirect');


    //  console.log(member_no);

    this.setState({ member_no: member_no });

    this.props.history.push('/customer/add/' + member_no);

  }


  handleAddnewCustomer = () => {

    this.setState({
      name: '',
      email: '',
      password: '',
      member_no: 0,

      national_id: 0,

      gardian: '',
      mother_name: '',
      phone_number: '',
      profession: '',
      date_of_bath: '',
      // formData.append('nomini', this.state.customerNomini);

      village_id: '',
      zila_id: '',
      thana_id: '',
      post_id: '',
      nationality_id: '',
      religious_id: '',
      current_address: ''
    });
    this.props.history.push('/customer/add/');
  }




  render() {
    let Loading;

    if (this.state.loading === true) {
      Loading = <Spinner />;
    }
    return (
      <Col lg={12}>
        <Header />

        <Container className="mt-5">
          <Row>
            <Col lg={6} md={6}>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <Button variant="secondary">সদস্য অনুসন্ধান </Button>
                </InputGroup.Prepend>
                <FormControl onChange={this.handleChange} name="name" aria-label="সদস্য অনুসন্ধান " placeholder="নাম  " />
                <InputGroup.Append>
                  <Button variant="success" onClick={this.handleSearch}>+</Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col lg={3} md={3}>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <Button variant="secondary">গ্রাম </Button>
                </InputGroup.Prepend>
                <Form.Control className="custome-select" as="select" onChange={this.handleVillageChange}>
                  <option value="all">সকল সদস্য </option>
                  {this.ShowVillages()}
                </Form.Control>

              </InputGroup>
            </Col>
            <Col lg={1} md={1}>
              <Button variant="danger" onClick={this.print}> <LocalizedMessage messageKey="button.print" /></Button>
            </Col>
            <Col lg={2} md={2}>
              <button onClick={this.handleAddnewCustomer} className="btn btn-info btn-block"><LocalizedMessage messageKey="button.add.customer" /> <i className="fas fa-plus"></i></button>
            </Col>

          </Row>

          <Row id="printContent">
            <Col lg={12} className="mt-3 d-flex justify-content-end">
              <small className="text-white ">সর্বমোট : <b>{EnglishToBanglaNumber(this.state.all_customers.length)}</b> টি ফলাফল প্রাপ্ত </small>
            </Col>

            <Table className="custom-warring" striped hover>
              <thead >
                <tr>
                  <th>সদস্য নম্বর </th>
                  {/* <th>ছবি </th> */}
                  <th>সদস্য নাম </th>
                  <th>ঠিকানা </th>
                </tr>
              </thead>
              <tbody>
                {this.ShowCustomers()}

              </tbody>

            </Table>
            {Loading}
          </Row>


          <ChoiceModal handleRedirect={this.handleRedirect} onRef={ref => this.choicemodel = ref} />
        </Container>





      </Col>


    )
  }

}
const mapStateToProps = state => ({

  all_customers: state.customer
});


export default withTranslation()(connect(mapStateToProps, { checkCustomer, getAllCustomers, CustomersSearch })(Customer));