/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import React, { Component }
    from 'react';
import { Link }
    from 'react-router-dom';

import { connect }
    from 'react-redux';
import { withTranslation }
    from 'react-i18next';

import Header from '../../common/Header';
import { checkCustomer } from '../../actions/collectionActions';

import LocalizedMessage from '../../common/LocalizedMessage';
import { Form, Row, Col, Container, Breadcrumb, Card, Button, Input, InputGroup, FormControl }
    from 'react-bootstrap';

import SaInput from '../../common/SaInput';

import SaDateInput from '../../common/SaDateInput';

import SaButtonInput from '../../common/SaButtonInput';


const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

class Expense extends Component {

    constructor() {
        super();

        var date = (new Date()).toLocaleDateString('en-US', DATE_OPTIONS);



        this.state = {
            next: '',
            customerNo: '',
            memoNo: '',
            sheetNo: '',
            date: date,
            share:'',
            saving:'',
            CustomerNoShow: true,
            collectionTypeShow: false,
            CheckShow: false
        }





    }

    checkCustomerNo = () => {
        this.props.checkCustomer(this.state.customerNo);

        this.setState({ CustomerNoShow: false, collectionTypeShow: true });


    }

    CheckData = () => {

        this.setState({ collectionTypeShow: false, CheckShow: true });

    }
    saveData = () => {


      //  console.log('save data');
    }

    cancelCollection = () => {
        this.setState({ collectionTypeShow: false, CustomerNoShow: true });
    }

    cancelCheck = () => {
        this.setState({ collectionTypeShow: true, CheckShow: false });
    }
    handleChange=(e)=>{


    }

    render() {
        return (
            <Col lg={12}>
                <Header />
                <Container>

                    <Card bg="custom-info" >
                        <Card.Header>

                            {this.state.CustomerNoShow && <h4 className="text-center font-weight-bold mt-4">  <LocalizedMessage messageKey="collection.title" /> </h4>}




                            {this.state.collectionTypeShow && <h4 className="text-center mt-4"><LocalizedMessage messageKey="collection.types" /></h4>}

                            {this.state.CheckShow && <h4 className="text-center mt-4"><LocalizedMessage messageKey="collection.overview" /></h4>}

                        </Card.Header>
                        <Card.Body>
                            <div className="steps-form mt-1 mb-4">
                                <div className="steps-row setup-panel">
                                    <div className="steps-step">
                                        <button type="button" className={this.state.CustomerNoShow ? ' btn btn-circle waves-effect waves-light btn-indigo' : ' btn btn-circle waves-effect waves-light   btn-info'}> 1</button>

                                    </div>
                                    <div className="steps-step">
                                        <button type="button" className={this.state.collectionTypeShow ? ' btn btn-circle waves-effect waves-light btn-indigo' : ' btn btn-circle waves-effect waves-light  btn-info'} >2</button>

                                    </div>
                                    <div className="steps-step">
                                        <button type="button" className={this.state.CheckShow ? 'btn btn-circle waves-effect waves-light  btn-indigo' : ' btn btn-circle waves-effect waves-light  btn-info'} >3</button>



                                    </div>
                                </div>
                            </div>

                            {this.state.CustomerNoShow &&
                                <Row>
                                    <h4 className="text-center"><LocalizedMessage messageKey="collection.customerNo" /></h4>
                                    <Col lg={3}>

                                    </Col>
                                    <Col lg={6} className="mt-2 mb-5">
                                           <SaInput handleChange={this.handleChange} autoFocus={true} readOnly={false} type="number" value={this.state.customerNo} setState={s => this.setState(s)} name="customerNo" label={< LocalizedMessage messageKey="customer.customerNo" />} />


                                    </Col>
                                    <Col lg={3}>

                                    </Col>
                                </Row>
                            }
                            {this.state.collectionTypeShow &&
                                <Col lg={12}>


                                    <Row>
                                        <Col lg={3}>
                                            <SaDateInput value={this.state.date} type="date" setState={s => this.setState(s)} name="date" label={< LocalizedMessage messageKey="collection.date" />} />


                                        </Col>
                                        <Col lg={3} >
                                               <SaInput handleChange={this.handleChange} readOnly={true} value={this.state.customerNo} type="number" setState={s => this.setState(s)} name="customerNo" label={< LocalizedMessage messageKey="customer.customerNo" />} />



                                        </Col>
                                        <Col lg={3}>
                                               <SaInput handleChange={this.handleChange} autoFocus={true} readOnly={false} value={this.state.memoNo} type="number" setState={s => this.setState(s)} name="memoNo" label={< LocalizedMessage messageKey="collection.memoNo" />} />

                                        </Col>
                                        <Col lg={3}>
                                               <SaInput handleChange={this.handleChange} autoFocus={false} readOnly={false} value={this.state.sheetNo} type="number" setState={s => this.setState(s)} name="sheetNo" label={< LocalizedMessage messageKey="collection.sheetNo" />} />

                                        </Col>
                                        
                                        <Col lg={3}>
                                            <SaButtonInput readOnly={false} value={this.state.share} type="number" setState={s => this.setState(s)} name="share" label={< LocalizedMessage messageKey="collection.types.share" />} />

                                        </Col>
                                        <Col lg={3}>
                                            <SaButtonInput readOnly={false} value={this.state.saving} type="number" setState={s => this.setState(s)} name="saving" label={< LocalizedMessage messageKey="collection.types.saving" />} />

                                        </Col>
                                        <Col lg={3}>
                                            <SaButtonInput readOnly={false} value={this.state.termSaving} type="number" setState={s => this.setState(s)} name="termSaving" label={< LocalizedMessage messageKey="collection.types.termSaving" />} />

                                        </Col>
                                        <Col lg={3}>
                                            <SaButtonInput readOnly={false} value={this.state.childSaving} type="number" setState={s => this.setState(s)} name="childSaving" label={< LocalizedMessage messageKey="collection.types.childSaving" />} />

                                        </Col>
                                        <Col lg={3}>
                                            <SaButtonInput readOnly={false} value={this.state.medicalSaving} type="number" setState={s => this.setState(s)} name="medicalSaving" label={< LocalizedMessage messageKey="collection.types.medicalSaving" />} />

                                        </Col>
                                        <Col lg={3}>
                                            <SaButtonInput readOnly={false} value={this.state.dailySaving} type="number" setState={s => this.setState(s)} name="dailySaving" label={< LocalizedMessage messageKey="collection.types.dailySaving" />} />

                                        </Col>
                                        <Col lg={3}>
                                            <SaButtonInput readOnly={false} value={this.state.share} type="number" setState={s => this.setState(s)} name="share" label={< LocalizedMessage messageKey="collection.types.termSaving" />} />

                                        </Col>
                                        <Col lg={3}>
                                            <SaButtonInput readOnly={false} value={this.state.saving} type="number" setState={s => this.setState(s)} name="saving" label={< LocalizedMessage messageKey="collection.types.childSaving" />} />

                                        </Col>
                                        <Col lg={3}>
                                            <SaButtonInput readOnly={false} value={this.state.saving} type="number" setState={s => this.setState(s)} name="saving" label={< LocalizedMessage messageKey="collection.types.childSaving" />} />

                                        </Col>
                                    </Row>
                                </Col>
                            }
                            {this.state.CheckShow &&
                                <Col lg={12}>
                                    <Row>
                                        <Col lg={12}>
                                            <h4 className="text-center">{this.state.date}</h4>
                                        </Col>


                                        <Col lg={4} md={6}>
                                            <Row>
                                                <Col  >

                                                </Col>
                                                <Col lg={12} md={12} className="pt-5">
                                                    <Card className="custom-card mt-5">
                                                        <Card.Img variant="top" className="rounded-circle " src="assets/img/humna.jpg" />
                                                        <Card.Body className="text-center">
                                                            <Card.Title>সদস্যের নাম</Card.Title>
                                                            <Card.Text>
                                                                সদস্যের ঠিকানা
                                                              </Card.Text>
                                                        </Card.Body>
                                                        <Card.Footer className="text-center">
                                                            <Link to="customer/profile"><small className="text-muted"> <LocalizedMessage messageKey="customer.ptofile" /></small> </Link>
                                                        </Card.Footer>
                                                    </Card>

                                                </Col>
                                                <Col >

                                                </Col>
                                            </Row>




                                        </Col>
                                        <Col lg={8} md={6} className="pt-5">
                                            <Row>
                                                <Col md="6" sm={12} sm={12}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <Button variant="outline-success">< LocalizedMessage messageKey="collection.memoNo" /></Button>
                                                        </InputGroup.Prepend>
                                                        <FormControl disabled={true} defaultValue={this.state.memoNo} aria-describedby="basic-addon1" />
                                                    </InputGroup>
                                                </Col>
                                                <Col md="6" sm={12} sm={12}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <Button variant="outline-success">< LocalizedMessage messageKey="collection.sheetNo" /></Button>
                                                        </InputGroup.Prepend>
                                                        <FormControl disabled={true} defaultValue={this.state.sheetNo} aria-describedby="basic-addon1" />
                                                    </InputGroup>
                                                </Col>
                                               
                                                <Col md="6">
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <Button variant="info">< LocalizedMessage messageKey="collection.types.share" /></Button>
                                                        </InputGroup.Prepend>
                                                        <FormControl disabled={true} defaultValue={this.state.share} aria-describedby="basic-addon1" />
                                                    </InputGroup>
                                                </Col>
                                                <Col md="6">
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <Button variant="info">< LocalizedMessage messageKey="collection.types.saving" /></Button>
                                                        </InputGroup.Prepend>
                                                        <FormControl disabled={true} defaultValue={this.state.saving}  aria-describedby="basic-addon1" />
                                                    </InputGroup>
                                                </Col>
                                                <Col md="6">
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <Button variant="info">< LocalizedMessage messageKey="collection.types.share" /></Button>
                                                        </InputGroup.Prepend>
                                                        <FormControl disabled={true} defaultValue={this.state.share}  aria-describedby="basic-addon1" />
                                                    </InputGroup>
                                                </Col>
                                                <Col md="6">
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <Button variant="info">< LocalizedMessage messageKey="collection.types.share" /></Button>
                                                        </InputGroup.Prepend>
                                                        <FormControl disabled={true} defaultValue={this.state.share}  aria-describedby="basic-addon1" />
                                                    </InputGroup>
                                                </Col>
                                                <Col md="6">
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <Button variant="info">< LocalizedMessage messageKey="collection.types.share" /></Button>
                                                        </InputGroup.Prepend>
                                                        <FormControl disabled={true} defaultValue={this.state.share}  aria-describedby="basic-addon1" />
                                                    </InputGroup>
                                                </Col>
                                                <Col md="6">
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <Button variant="info">< LocalizedMessage messageKey="collection.types.share" /></Button>
                                                        </InputGroup.Prepend>
                                                        <FormControl disabled={true} defaultValue={this.state.share}  aria-describedby="basic-addon1" />
                                                    </InputGroup>
                                                </Col>
                                                <Col md="6">
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <Button variant="info">< LocalizedMessage messageKey="collection.types.share" /></Button>
                                                        </InputGroup.Prepend>
                                                        <FormControl disabled={true} defaultValue={this.state.share}  aria-describedby="basic-addon1" />
                                                    </InputGroup>
                                                </Col>
                                                
                                            </Row>



                                        </Col>
                                    </Row>
                                </Col>
                            }



                        </Card.Body>
                        <Card.Footer>
                            <Row>
                                <Col lg="6" md={6} xs={6}>


                                    {this.state.collectionTypeShow &&
                                        <button onClick={this.cancelCollection} className="btn  btn-rounded prevBtn  waves-effect waves-light" type="button"> <LocalizedMessage messageKey="button.cancel" /></button>
                                    }
                                    {this.state.CheckShow &&
                                        <button onClick={this.cancelCheck} className="btn  btn-rounded prevBtn  waves-effect waves-light" type="button"> <LocalizedMessage messageKey="button.cancel" /></button>
                                    }



                                </Col>
                                <Col lg="6" md={6} xs={6}>
                                    {this.state.CustomerNoShow &&
                                        <button onClick={this.checkCustomerNo} className="btn btn-indigo btn-rounded nextBtn float-right waves-effect waves-light" type="button"> <LocalizedMessage messageKey="button.next" /></button>
                                    }
                                    {this.state.collectionTypeShow &&
                                        <button onClick={this.CheckData} className="btn btn-indigo btn-rounded nextBtn float-right waves-effect waves-light" type="button"> <LocalizedMessage messageKey="button.next" /></button>
                                    }
                                    {this.state.CheckShow &&
                                        <button onClick={this.saveData} className="btn btn-indigo btn-rounded prevBtn  float-right waves-effect waves-light" type="button"> <LocalizedMessage messageKey="button.save" /></button>
                                    }


                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>



                </Container>


            </Col>


        )
    }

}
const mapStateToProps = state => ({
    message: state.message
});


export default withTranslation()(connect(mapStateToProps, { checkCustomer })(Expense));